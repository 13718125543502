import { connect } from 'react-redux';
import { conditionAction } from '../_actions';
import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import {GetUrlParam, showMessage} from "../_helpers";
import ModalBoxCondition from "./modal.component";

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(10),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});


class Condition extends Component {
    componentDidMount() {
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        dispatch(conditionAction.getCondition());
        var paramsMap = GetUrlParam();
        if ((paramsMap.get('show_message')) && (paramsMap.get('show_message_type'))) {
            showMessage(paramsMap.get('show_message_type'),paramsMap.get('show_message'));
        }
        dispatch(conditionAction.getConditionTypes());
        dispatch(conditionAction.getConditionParams());
        dispatch(conditionAction.getConditionConditions());
    }
    handleChange = event => {
        this.setState({
            anchor: event.target.value,
        });
    };
    handleClick = (event, id) => {
        const { dispatch } = this.props;
        dispatch(conditionAction.deleteConditionById(id))
    };

    render() {
        const { classes } = this.props;
        const { condition } = this.props.condition;

        let conditionSymbol = new Array;
        if (this.props.conditioncondition) {
            for (let value of this.props.conditioncondition.conditions) {
                if (value.key && value.value) {
                    conditionSymbol[value.value] = value.key;
                }
            }
//            console.log(conditionSymbol);
        }

        let conditionType = new Array;
        if (this.props.conditiontype) {
            for (let value of this.props.conditiontype.types) {
                if (value.key && value.value) {
                    conditionType[value.value] = value.key;
                }
            }
        }

        let conditionParam = new Array;
        if (this.props.conditionparam) {
            for (let value of this.props.conditionparam.params) {
                if (value.key && value.value) {
                    conditionParam[value.value] = value.key;
                }
            }
        }

        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar/>
                    <Nav />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Typography variant="h4">{'Conditions'}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                                <ModalBoxCondition param={ { props: this.props} }/>
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={5}>
                            <Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Login</TableCell>
                                            <TableCell numeric>Type</TableCell>
                                            <TableCell numeric>Group</TableCell>
                                            <TableCell numeric>Priority</TableCell>
                                            <TableCell numeric>Param</TableCell>
                                            <TableCell numeric>Condition</TableCell>
                                            <TableCell>Target</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Factor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {condition.map(n => {
                                            return (
                                                <TableRow key={n.id}>
                                                    <TableCell component="th" scope="row">
                                                        {n.user_login}
                                                    </TableCell>
                                                    <TableCell numeric>{conditionType[n.type]}</TableCell>
                                                    <TableCell numeric>{n.group}</TableCell>
                                                    <TableCell numeric>{n.priority}</TableCell>
                                                    <TableCell numeric>{conditionParam[n.param]}</TableCell>
                                                    <TableCell>{conditionSymbol[n.condition_symbol]}</TableCell>
                                                    <TableCell>{n.target}</TableCell>
                                                    <TableCell>{n.value}</TableCell>
                                                    <TableCell>{n.factor}</TableCell>
                                                    <TableCell>
                                                        <ModalBoxCondition param={ { props: this.props, style: "icon", id: n.id} }/>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
Condition.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) =>{
    return {
        condition : state.condition,
        conditioncondition : state.conditioncondition,
        conditiontype : state.conditiontype,
        conditionparam : state.conditionparam,
    };
}
const connectedConditionPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Condition)));
export { connectedConditionPage as Condition };