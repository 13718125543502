import { connect } from 'react-redux';
import {symbolAction} from '../_actions';
import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import './symbol.component.css'

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(10),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

let prevCPrices = new Map();
const ws = new WebSocket('wss://ws.markquotes.com');

class Symbol extends Component {

    constructor(props) {
        super(props);
        this.state = {cprices: new Map()};
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(symbolAction.getSymbols("symbol","asc"));
        dispatch(symbolAction.getCountries());
        dispatch(symbolAction.getSectors(""));
        dispatch(symbolAction.getIndustries("",""));
    }
    componentWillMount() {

        let cp = new Map();
        let cp_old = new Map();
        let i=0;

        const apiCall = {
            event: 'subscribe',
            data: { channel: 'lastprices' },
        };

        function updatePrevCPrices(value) {
            prevCPrices = value;
            return true
        }

        ws.onopen = () => {
            ws.send(JSON.stringify(apiCall));
            console.log('WebSocket Client Connected');
        };
        ws.onmessage = (event) => {
            const json = JSON.parse(event.data);

            try {
                cp.set(json.symbol, json.cprice);

                if (i>100) {
                    i=0;

                    if (updatePrevCPrices(cp_old)) {
                        this.setState({
                            cprices: cp
                        });
                        cp_old = new Map(cp);
                    }

                }
                i= i+1;
            } catch (err) {
                console.log(err);
            }
        };
    }

    componentWillUnmount() {
        //clean up function
        prevCPrices = new Map();
        ws.close();
    }

    handleClick(orderBy,order){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;


//        if (order === undefined) {order=""}
//        if (orderBy === undefined) {orderBy=""}

        if (order==="asc") {order="desc"} else {order="asc"}

        this.props.symbol.order = order;
        this.props.symbol.orderby = orderBy;

        var filters = this.props.symbol.filters;
        var q = this.props.symbol.q;

        dispatch(symbolAction.getSymbols(orderBy, order, filters, q));
    }

    handleChange = prop => event => {
        const { dispatch } = this.props;
        var order = this.props.symbol.order;
        var orderBy = this.props.symbol.orderby;
        var filter_value = event.target.value;
        var filter_name = prop;
        var q = this.props.symbol.q;

        if (filter_name === "country") {
            this.props.symbol.filter_country = filter_value
        }
        if (filter_name === "currency") {
            this.props.symbol.filter_currency = filter_value
        }
        if (filter_name === "sector") {
            this.props.symbol.filter_sector = filter_value
        }
        if (filter_name === "industry") {
            this.props.symbol.filter_industry = filter_value
        }
        if (filter_name === "price_min") {
            this.props.symbol.filter_price_min = filter_value
        }
        if (filter_name === "price_max") {
            this.props.symbol.filter_price_max = filter_value
        }
        if (filter_name === "q") {
            this.props.symbol.q = filter_value
            q = filter_value
        }

        var filters = "currency:"+this.props.symbol.filter_currency+"@price_min:"+this.props.symbol.filter_price_min+"@price_max:"+this.props.symbol.filter_price_max+"@country:"+this.props.symbol.filter_country+"@sector:"+this.props.symbol.filter_sector+"@industry:"+this.props.symbol.filter_industry
        this.props.symbol.filters = filters;

        dispatch(symbolAction.getSymbols(orderBy, order, filters, q));
        dispatch(symbolAction.getSectors(this.props.symbol.filter_country));
        dispatch(symbolAction.getIndustries(this.props.symbol.filter_country,this.props.symbol.filter_sector));
    };


    render() {
        const { classes } = this.props;
        const { symbol } = this.props.symbol;
        const { country } = this.props.country;
        const { sector } = this.props.sector;
        const { industry } = this.props.industry;
        const order = this.props.symbol.order;
        const orderBy = this.props.symbol.orderby;
        function FormatTime(timestamp) {
            return moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss");
        }
        function renderTableCell(livePrice, cprice, oprice, prevPrice) {
            if (livePrice && livePrice != 0) {
                cprice = livePrice
            }
            if (cprice < oprice) {
                return (
                    <TableCell align={"center"}>
                        <div className={isPriceChange(livePrice,prevPrice)}><div style={{backgroundColor:'red', color: 'white',fontWeight: 'bold'}}>{roundDown(cprice,2)}</div></div>
                    </TableCell>
                )
            } else if (cprice > oprice) {
                return (
                    <TableCell align={"center"}>
                        <div className={isPriceChange(livePrice,prevPrice)}><div style={{backgroundColor:'green', color: 'white',fontWeight: 'bold'}}>{roundDown(cprice,2)}</div></div>
                    </TableCell>
                )
            } else {
                return (
                    <TableCell align={"center"}>
                        <div className={isPriceChange(livePrice,prevPrice)}><div style={{backgroundColor:'lightgray', color: 'black', fontWeight: 'bold'}}>{roundDown(cprice,2)}</div></div>
                    </TableCell>
                )
            }
        }

        function roundDown(number, decimals) {
            decimals = decimals || 0;
            return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
        }

        function abbreviateNumber(value) {
            var newValue = value;
            if (value >= 1000) {
                var suffixes = ["", "K", "M", "B","T","Q"];
                var suffixNum = Math.floor( (""+value).length/3 );
                var shortValue = '';
                for (var precision = 3; precision >= 1; precision--) {
                    shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                    var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                    if (dotLessShortValue.length <= 3) { break; }
                }
                if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
                newValue = shortValue+suffixes[suffixNum];
            }
            return newValue;
        }

        function isPriceChange(new_val,old_val) {
            if (new_val !== old_val) {
                return ''
            }
            return 'changePrice'
        }


        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar/>
                    <Nav />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        <Grid container spacing={8}>
                            <Grid item xs={5}>
                                <Typography variant="h4">{'Trandings'}</Typography>
                            </Grid>

                        <Grid item xs={3}>
                            <TextField
                                id="search"
                                label="Search"
                                value={this.props.symbol.q}
                                type = "search"
                                className={classes.textField}
                                onChange = {this.handleChange('q')}
                            />

                        </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    id="price_min"
                                    label="Min price"
                                    value={this.props.symbol.filter_price_min}
                                    type="number"
                                    className={classes.textField}
                                    onChange = {this.handleChange('price_min')}
                                />
                            </Grid>
                                <Grid item xs={2}>
                                <TextField
                                    id="price_max"
                                    label="Max price"
                                    value={this.props.symbol.filter_price_max}
                                    type="number"
                                    className={classes.textField}
                                    onChange = {this.handleChange('price_max')}
                                />
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={5}>
                            <Paper className={classes.root}>

                                <Table className={classes.table}>
                                    <TableHead showSortingControls>
                                        <TableRow>
                                            <TableCell numeric></TableCell>
                                            <TableCell numeric sortDirection={orderBy === "symbol" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "symbol"}
                                                    direction={orderBy === "symbol" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("symbol",order)}
                                                >
                                                    Ticker
                                                    {orderBy === "symbol" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "name" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "name"}
                                                    direction={orderBy === "name" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("name",order)}
                                                >
                                                    Name
                                                    {orderBy === "name" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell numeric>Currency
                                                <Select
                                                    native
                                                    id="currency"
                                                    label="Currency"
                                                    value={this.props.symbol.filter_currency}
                                                    onChange={this.handleChange('currency')}

                                                >
                                                    <option value="">Select currency</option>
                                                    <option value="USD">USD</option>
                                                    <option value="RUR">RUR</option>
                                                    <option value="EUR">EUR</option>
                                                </Select>
                                            </TableCell>
                                            <TableCell numeric>Country
                                                <Select
                                                    native
                                                    id="country"
                                                    label="Country"
                                                    value={this.props.symbol.filter_country}
                                                    onChange={this.handleChange('country')}

                                                >
                                                    <option value="">Select country</option>
                                                    {country.map(n => {
                                                        return (
                                                            <option value={n.country}>{n.country}</option>
                                                        );
                                                    })}
                                                </Select>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "sector" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "sector"}
                                                    direction={orderBy === "sector" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("sector",order)}
                                                >
                                                    Sector
                                                    {orderBy === "sector" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                                <Select
                                                    native
                                                    id="sector"
                                                    label="Sector"
                                                    value={this.props.symbol.filter_sector}
                                                    onChange={this.handleChange('sector')}

                                                >
                                                    <option value="">Select sector</option>
                                                    {sector.map(n => {
                                                        return (
                                                            <option value={n.sector}>{n.sector}</option>
                                                        );
                                                    })}
                                                </Select>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "industry" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "industry"}
                                                    direction={orderBy === "industry" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("industry",order)}
                                                >
                                                    Industry
                                                    {orderBy === "industry" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                                <Select
                                                    native
                                                    id="industry"
                                                    label="Industry"
                                                    value={this.props.symbol.filter_industry}
                                                    onChange={this.handleChange('industry')}

                                                >
                                                    <option value="">Select industry</option>
                                                    {industry.map(n => {
                                                        return (
                                                            <option value={n.industry}>{n.industry}</option>
                                                        );
                                                    })}
                                                </Select>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "marketcap" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "marketcap"}
                                                    direction={orderBy === "marketcap" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("marketcap",order)}
                                                >
                                                    MarketCap
                                                    {orderBy === "marketcap" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "volume" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "volume"}
                                                    direction={orderBy === "volume" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("volume",order)}
                                                >
                                                    Volume
                                                    {orderBy === "volume" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "cprice" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "cprice"}
                                                    direction={orderBy === "cprice" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("cprice",order)}
                                                >
                                                    Current Price
                                                    {orderBy === "cprice" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "synctime" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "synctime"}
                                                    direction={orderBy === "synctime" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("synctime",order)}
                                                >
                                                    Sync Time
                                                    {orderBy === "synctime" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell numeric sortDirection={orderBy === "utime" ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === "utime"}
                                                    direction={orderBy === "utime" ? order : 'desc'}
                                                    onClick={(event) => this.handleClick("utime",order)}
                                                >
                                                    Update Time
                                                    {orderBy === "utime" ? (
                                                        <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {symbol.map(n => {
                                            return (
                                                <TableRow key={n.symbol}>
                                                    <TableCell numeric><img src={'/static/media/logos/'+n.symbol+'.png'} width={20} height={20}></img></TableCell>
                                                    <TableCell numeric><a href={'/symbols/'+n.symbol}>{n.symbol}</a></TableCell>
                                                    <TableCell numeric>{n.name}</TableCell>
                                                    <TableCell numeric>{n.currency}</TableCell>
                                                    <TableCell numeric>{n.country}</TableCell>
                                                    <TableCell numeric>{n.sector}</TableCell>
                                                    <TableCell numeric>{n.industry}</TableCell>
                                                    <TableCell numeric>{abbreviateNumber(n.marketcap)}
                                                    </TableCell>
                                                    <TableCell numeric>{abbreviateNumber(n.volume)}</TableCell>
                                                    {renderTableCell(this.state.cprices.get(n.symbol),n.cprice, n.oprice, prevCPrices.get(n.symbol))}
                                                    <TableCell numeric>{FormatTime(n.synctime)}</TableCell>
                                                    <TableCell numeric>{FormatTime(n.utime)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
Symbol.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) =>{
    return {
        cprices: state.cprices,
        symbol : state.symbol,
        country: state.country,
        sector: state.sector,
        industry:  state.industry,
    };
}
const connectedSymbolPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Symbol)));
export { connectedSymbolPage as Symbol };
