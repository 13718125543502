import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { authActions } from '../_actions';
// import { history } from '../_helpers';
import Logo from '../assets/images/logo.png'
import history from '../_helpers/history';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import './login.component.css'
const styles = theme => ({
    body: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#054D29',
    },
    input: {
        display: 'none',
    },
});

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            login: '',
            password: '',
            showPassword: false,
        }
    }
    componentDidMount() {
        if(localStorage.getItem('auth')){
            history.push('/home');
        }
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    login = event =>{
        this.setState({ submitted: true });
        const { login, password } = this.state;
        const { dispatch } = this.props;
        if (login && password) {
            dispatch(authActions.login(login, password));
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className="main-container">
                <div className="back-opacity"></div>
            <div className="login-container">
                <Grid container spacing={{
                    xs: 0,
                    sm: 2,
                    md: 5
                }}>
                    <Grid item xs={12} sm={2} md={2}>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <img src={Logo} className="logo-img"/>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <div className="login-box">
                        <Paper className={classes.paper}>
                            <Typography><h1>{'Login'}</h1></Typography>
                        </Paper>
                        <Paper className={classes.paper}>
                            <div>
                                <TextField
                                    label="Username"
                                    value={this.state.username}
                                    className={classes.textField}
                                    onChange = {this.handleChange('login')}
                                />
                                <br/><br/>
                                <TextField
                                    label="Password"
                                    autoComplete="current-password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    className={classes.textField}
                                    value={this.state.password}
                                    onChange={this.handleChange('password')}
                                />
                                <br/><br/>
                                <Button variant="contained" color="primary" className={classes.button} onClick={(event)=>{this.login()}}>Login</Button>
                            </div>
                        </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                    </Grid>
                </Grid>
            </div>
            </div>
        );
    }
}
Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>{
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}
const connectedLoginPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Login)));
export { connectedLoginPage as Login };