import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showMessage = (severity, message) => {
    if (severity === "error") {
        toast.error(message);
    }
    if (severity === "success") {
        toast.success(message);
    }
}