import axios from 'axios';
import config from '../config/config';
import history from "../_helpers/history";


export const userService = {
    get,
    post,
    put,
    deleteDetail
};

const responseSuccessHandler = response => {
    return response;
};

const responseErrorHandler = error => {
    if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
        if ((error.response.status === 401) || (error.response.status === 403)) {
            localStorage.removeItem('auth');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
            history.push('/');
        }
    } else if (error.request) {
        console.error(error.request);
    } else {
        console.error('Error', error.message);
    }
    console.error(error.config);

    return Promise.reject(error);
}


function get(apiEndpoint){
    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );
    return axios.get(config.baseUrl+apiEndpoint, getOptions())
        .then((response)=>{
            return response;
        }).catch((err)=>{
            console.log(err);
        })
}
function post(apiEndpoint, payload){
    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );
    return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
        .then((response)=>{
            return response;
        }).catch((err)=>{
            console.log(err);
        })
}
function put(apiEndpoint, payload){
    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );
    return axios.put(config.baseUrl+apiEndpoint, payload, getOptions())
        .then((response)=>{
            return response;
        }).catch((err)=>{
            console.log(err);
        })
}
function deleteDetail(apiEndpoint){
    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );
    return axios.delete(config.baseUrl+apiEndpoint, getOptions())
        .then((response)=>{
            return response;
        }).catch((err)=>{
            console.log(err);
        })
}
function getOptions(){
    let options = {};
    if(localStorage.getItem('access_token')){
        options.headers = { 'authorization': "Bearer " + localStorage.getItem('access_token') };
    }
    return options;
}