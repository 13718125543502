
export const GetUrlParam = () => {
    let urlElements = window.location.href.split('/')
    let onlyParams = urlElements[urlElements.length - 1].split('?');
    let params = onlyParams[onlyParams.length - 1].split('&');
    const resultMap = new Map();
    for (let value of params) {
        let keyValue = value.split('=');
        if (keyValue[0] && keyValue[1]) {
            resultMap.set(keyValue[0], decodeURI(keyValue[1]));
        }
    }
    console.log(resultMap);
    return resultMap;
}
