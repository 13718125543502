const initialState = {
    anchor: 'left',
    symbol: [],
    cprices: [],
    indicators: [],
    open: false,
    desc: '',
    currency: 'USD',
    order: "asc",
    orderby: "symbol",
    last_utime: 0,
    last_synctime: 0,
};

export function symbol(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_SYMBOL':
            return {
                ...state,
                symbol: action.symbol
            };
        case 'SYMBOL_DETAIL':
            return {
                ...state,
                symbol: action.symbol,
                indicators: action.symbol.indicators,
                currency: action.currency,
                country: action.country,
                synctime: action.synctime,
                utime: action.utime
            };
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}