import { userService } from '../_services/';
import history from '../_helpers/history';

export const userAction = {
    getUser,
    getUserById,
    onChangeProps,
    editUserInfo,
    createUser,
    deleteUserById
};
function getUser(){
    return dispatch => {
        let apiEndpoint = 'users';
        userService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeUsersList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function createUser(payload){
    return dispatch => {
        let apiEndpoint = 'users/register';
        userService.post(apiEndpoint, payload)
            .then((response)=>{
                dispatch(createUserInfo());
                history.push('/users');
            })
    }
}
function getUserById(id){
    return dispatch => {
        let apiEndpoint = 'users/'+ id;
        userService.get(apiEndpoint)
            .then((response)=>{
                dispatch(editUserDetails(response.data));
            })
    };
}
function onChangeProps(props, event){
    return dispatch =>{
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
function editUserInfo(id, payload){
    return dispatch => {
        let apiEndpoint = 'users/'+ id;
        userService.put(apiEndpoint, payload)
            .then((response)=>{
                dispatch(updatedUserInfo());
                history.push('/users');
            })
    }
}
function deleteUserById(id){
    return dispatch => {
        let apiEndpoint = 'users/'+ id;
        userService.deleteDetail(apiEndpoint)
            .then((response)=>{
                dispatch(deleteUserDetails());
                dispatch(userAction.getUser());
            })
    };
}
export function changeUsersList(user){
    return{
        type: "FETCHED_ALL_USER",
        user: user
    }
}
export function handleOnChangeProps(props, value){
    return{
        type: "HANDLE_ON_CHANGE",
        props: props,
        value: value
    }
}
export function editUserDetails(user){
    return{
        type: "USER_DETAIL",
        id: user.id,
        login: user.login,
        fname: user.fname,
        lname: user.lname,
        currency: user.currency,
        saldo: user.saldo,
        phone: user.phone,
        lasttime: user.lasttime,
        utime: user.utime,
        ctime: user.ctime
    }
}
export function updatedUserInfo(){
    return{
        type: "USER_UPDATED"
    }
}
export function createUserInfo(){
    return{
        type: "USER_CREATED_SUCCESSFULLY"
    }
}
export function deleteUserDetails(){
    return{
        type: "DELETED_USER_DETAILS"
    }
}