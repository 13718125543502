const initialState = {
    anchor: 'left',
    user: [],
    open: false,
    id: '',
    fname: '',
    lname: '',
    login: '',
    phone: 79,
    saldo: 0,
    currency: '',
    ctime: 0,
    utime: 0,
    lasttime: 0

};
export function user(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_USER':
            return {
                ...state,
                user: action.user
            };
        case 'USER_DETAIL':
            return {
                ...state,
                id: action.id,
                lname: action.lname,
                fname: action.fname,
                currency: action.currency,
                phone: action.phone,
                saldo: action.saldo,
                login: action.login,
                ctime: action.ctime,
                utime: action.utime,
                lasttime: action.lasttime
            };
        case "USER_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}