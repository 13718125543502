const initialState = {
    anchor: 'left',
    condition: [],
    types: [],
    open: false,
    id: '',
    factor: 1,
    active: true,
    last_rule: false,
    group:'',
    group_err:'',
    priority: 1,
    priority_err:'',
    user_id: '',
    user_id_err:'',
    condition_type: 0,
    condition_type_err:'',
    param: '',
    param_err:'',
    condition_symbol: 0,
    condition_symbol_err:'',
    target: '',
    target_err:'',
    target_resolution: '',
    target_from: 0,
    target_to: 0,
    value: '',
};
export function condition(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITION':
            return {
                ...state,
                condition: action.condition
            };
        case 'CONDITION_DETAIL':
            return {
                ...state,
                id: action.id,
                factor: action.factor,
                active: action.active,
                last_rule: action.last_rule,
                group: action.group,
                priority: action.priority,
                user_id: action.user_id,
                condition_type: action.condition_type,
                param: action.param,
                condition_symbol: action.condition_symbol,
                target: action.target,
                target_resolution: action.target_resolution,
                target_from: action.target_from,
                target_to: action.target_to,
                value: action.value,
            };
        case "CONDITION_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}