const initialState = {
    anchor: 'left',
    conditions: [],
    symbols: [],
    currency: "",
    sum: 0,
    amount: 0,
    open: false,
};

export function conditioncheck(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITIONGROUPCHECK':
            return {
                ...state,
                conditions: action.conditions,
                symbols: action.symbols,
                amount: action.amount,
                sum: action.sum,
                currency: action.currency,
            };

        default:
            return state
    }
}