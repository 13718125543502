import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import {conditionAction, userAction} from '../_actions';
import { withRouter } from 'react-router-dom';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {showMessage} from '../_helpers/notify';
import ModalBox from "../home/modal.component";
import ModalBoxCondition from "./modal.component";


const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    contentRoot: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});


class EditCondition extends Component {

    handleChange = prop => event => {
        const { dispatch } = this.props;
//        this.handleCheck(event);

        dispatch(conditionAction.onChangeProps(prop, event));
    };

    handleCheckboxChange = prop => event => {
        const { dispatch } = this.props;

        var chbox_active =document.getElementById('active');
        if (chbox_active && chbox_active.checked) {
            this.props.condition.active = true;
        }
        else {
            this.props.condition.active = false;
        }
        var chbox_last_rule =document.getElementById('last_rule');
        if (chbox_last_rule && chbox_last_rule.checked) {
            this.props.condition.last_rule = true;
        }
        else {
            this.props.condition.last_rule = false;
        }
        dispatch(conditionAction.onChangeProps("", event));
    };

    componentDidMount() {
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        if(params.id){
            dispatch(conditionAction.getConditionById(params.id));
        }
        dispatch(userAction.getUser());
        dispatch(conditionAction.getConditionTypes());
        dispatch(conditionAction.getConditionParams());
        dispatch(conditionAction.getConditionConditions());
        dispatch(conditionAction.getConditionResolutions());

    }
    handleCheck(event) {
        const {dispatch} = this.props;
        var error = false;
        if (this.props.condition.user_id === "") {
            this.props.condition.user_id_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.user_id_err = ''
        }
        if ((this.props.condition.condition_type === "") || (this.props.condition.condition_type === 0)) {
            this.props.condition.condition_type_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.condition_type_err = ''
        }
        if (this.props.condition.group === "") {
            this.props.condition.group_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.group_err = ''
        }
        if (this.props.condition.priority === "") {
            this.props.condition.priority_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.priority_err = ''
        }
        if (this.props.condition.param === "") {
            this.props.condition.param_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.param_err = ''
        }
        if ((this.props.condition.condition_symbol === "") || (this.props.condition.condition_symbol === 0)) {
            this.props.condition.condition_symbol_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.condition_symbol_err = ''
        }
        if (this.props.condition.target === "") {
            this.props.condition.target_err = 'Invalid value';
            error = true;
        } else {
            this.props.condition.target_err = ''
        }

        dispatch(conditionAction.onChangeProps("", event));
        return error
    }
    handleClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        const id = this.props.params.id;


        var error = this.handleCheck(event);

        if (error) {
            showMessage("error",'Invalid value! Please fix error and try again');
            dispatch(conditionAction.onChangeProps("", event));
            return
        }

        let payload={
            user_id: this.props.condition.user_id,
            type: Number.parseInt(this.props.condition.condition_type),
            group: this.props.condition.group,
            priority: Number.parseInt(this.props.condition.priority),
            param: this.props.condition.param,
            target: this.props.condition.target,
            value: this.props.condition.value,
            active: this.props.condition.active,
            last_rule: this.props.condition.last_rule,
            target_resolution: this.props.condition.target_resolution,
            target_from: Number.parseInt(this.props.condition.target_from),
            target_to: Number.parseInt(this.props.condition.target_to),
            condition_symbol: Number.parseInt(this.props.condition.condition_symbol),
            factor: Number.parseFloat(this.props.condition.factor),
        }

        if(id){
            dispatch(conditionAction.editConditionInfo(id, payload));
        }else{
            dispatch(conditionAction.createCondition(payload));
        }
        this.props.params.modalOpen(false);
        dispatch(conditionAction.getCondition());
        this.render();
    }


    render() {
        const { classes } = this.props;
        const { user } = this.props.user;
        const id = this.props.params.id;
        let user_id = this.props.params.user_id;
        let condition_type_id = this.props.params.condition_type_id;
        let group_name = this.props.params.group_name;

        if (this.props.condition.user_id && this.props.condition.user_id != "") {
            user_id = this.props.condition.user_id;
        }
        if (this.props.condition.condition_type && this.props.condition.condition_type>0) {
            condition_type_id = this.props.condition.condition_type;
        }
        if (this.props.condition.group && this.props.condition.group!="") {
            group_name = this.props.condition.group;
        }

        console.log(this.props);
        const { match : { params } } = this.props;
        function InsertText(props) {
            return <Typography variant="h4">{'Add New Condition'}</Typography>;
        }

        function EditText(props) {
            return <Typography variant="h4">{'Edit Condition'}</Typography>;
        }

        function SegHeader() {
            if(id){
                return <EditText />;
            }
            return <InsertText />;
        }



        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>



                    <main className={classes.content}>

                        <SegHeader />
                        <Grid container spacing={10}>
                            <Grid item xs={12}>
                                <div>
                                    <Paper className={classes.contentRoot} elevation={1}>
                                        <form className={classes.container}>
                                            <Grid container spacing={12}>
                                                <Grid item xs={2}>
                                                    <InputLabel shrink>
                                                        User
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="user_id"
                                                        label="User"
                                                        value={user_id}
                                                        errorText= {this.props.condition.user_id_err}
                                                        error ={this.props.condition.user_id_err.length === 0 ? false : true }
                                                        onChange={this.handleChange('user_id')}
                                                    >
                                                        <option value="">Select login</option>
                                                        {user.map(n => {
                                                            return (
                                                                <option value={n.id}>{n.login}</option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <InputLabel shrink>
                                                        Type
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="type"
                                                        label="Type"
                                                        errorText= {this.props.condition.condition_type_err}
                                                        error ={this.props.condition.condition_type_err.length === 0 ? false : true }
                                                        value={condition_type_id}
                                                        onChange={this.handleChange('condition_type')}
                                                    >
                                                        <option value="">Select type</option>
                                                        {this.props.conditiontype.types.map(n => {
                                                            return (
                                                                <option value={n.value}>{n.key}</option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="group"
                                                        label="Group"
                                                        className={classes.textField}
                                                        value={group_name}
                                                        onChange={this.handleChange('group')}
                                                        errorText= {this.props.condition.group_err}
                                                        error ={this.props.condition.group_err.length === 0 ? false : true }
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="priority"
                                                        label="Priority"
                                                        style={{width : '80px'}}
                                                        className={classes.textField}
                                                        value={this.props.condition.priority}
                                                        errorText= {this.props.condition.priority_err}
                                                        error ={this.props.condition.priority_err.length === 0 ? false : true }
                                                        onChange={this.handleChange('priority')}
                                                    />
                                                </Grid>

                                                <Grid item xs={2}>

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id="active"
                                                                    name="active"
                                                                    color="primary"
                                                                    defaultChecked
                                                                    onChange={this.handleCheckboxChange("active")}
                                                                />
                                                            }
                                                            label="Active"
                                                        />
                                                </Grid>
                                                        <Grid item xs={2}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id="last_rule"
                                                                    name="last_rule"
                                                                    color="secondary"
                                                                    onChange={this.handleCheckboxChange("last_rule")}
                                                                />
                                                            }
                                                            label="Last rule"
                                                        />

                                                </Grid>

                                            </Grid>
                                            <br />

                                            <Grid container spacing={12}>
                                                <Grid item xs={2}>
                                                    <InputLabel shrink>
                                                        Parameter
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="param"
                                                        label="Paramemer"
                                                        errorText= {this.props.condition.param_err}
                                                        error ={this.props.condition.param_err.length === 0 ? false : true }
                                                        value={this.props.condition.param}
                                                        onChange={this.handleChange('param')}
                                                    >
                                                        <option value="">Select parameter</option>
                                                        {this.props.conditionparam.params.map(n => {
                                                            return (
                                                                <option value={n.value}>{n.key}</option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <InputLabel shrink>
                                                        Condition
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="condition_symbol"
                                                        label="Condition"
                                                        errorText= {this.props.condition.condition_symbol_err}
                                                        error ={this.props.condition.condition_symbol_err.length === 0 ? false : true }
                                                        value={this.props.condition.condition_symbol}
                                                        onChange={this.handleChange('condition_symbol')}
                                                    >
                                                        <option value="">Select condition</option>
                                                        {this.props.conditioncondition.conditions.map(n => {
                                                            return (
                                                                <option value={n.value}>{n.key}</option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <InputLabel shrink>
                                                        Target
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="target"
                                                        label="Target"
                                                        errorText= {this.props.condition.target_err}
                                                        error ={this.props.condition.target_err.length === 0 ? false : true }
                                                        value={this.props.condition.target}
                                                        onChange={this.handleChange('target')}
                                                    >
                                                        <option value="">Select target</option>
                                                        {this.props.conditionparam.params.map(n => {
                                                            return (
                                                                <option value={n.value}>{n.key}</option>
                                                            );
                                                        })}
                                                        <option value="value_string">Value String</option>
                                                        <option value="value_float">Value Float</option>
                                                        <option value="value_int">Value Integer</option>
                                                    </Select>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <TextField
                                                        id="value"
                                                        label="Value"
                                                        className={classes.textField}
                                                        value={this.props.condition.value}
                                                        onChange={this.handleChange('value')}
                                                    />
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <InputLabel shrink>
                                                        Resolution
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="target_resolution"
                                                        label="Resolution"
                                                        value={this.props.condition.target_resolution}
                                                        onChange={this.handleChange('target_resolution')}
                                                    >
                                                        <option value="">Select resolution</option>
                                                        {this.props.conditionresolution.resolutions.map(n => {
                                                            return (
                                                                <option value={n.value}>{n.key}</option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <TextField
                                                        id="target_from"
                                                        label="Time From"
                                                        style={{width : '80px'}}
                                                        className={classes.textField}
                                                        value={this.props.condition.target_from}
                                                        onChange={this.handleChange('target_from')}
                                                    />
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <TextField
                                                        id="target_to"
                                                        label="Time To"
                                                        style={{width : '80px'}}
                                                        className={classes.textField}
                                                        value={this.props.condition.target_to}
                                                        onChange={this.handleChange('target_to')}
                                                    />
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <TextField
                                                        id="factor"
                                                        label="Factor"
                                                        style={{width : '80px'}}
                                                        className={classes.textField}
                                                        value={this.props.condition.factor}
                                                        onChange={this.handleChange('factor')}
                                                    />
                                                </Grid>


                                            </Grid>
                                            <br/>
                                                        <div style={{position: 'relative',display: 'flex', padding: 10, alignItems: 'center', justifyContent: 'center',}}>
                                                            <Button variant="contained" color="primary" className={classes.button} onClick={(event) => this.handleClick(event)}>Save</Button>
                                                        </div>

                                        </form>
                                    </Paper>
                                </div>
                            </Grid>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}

EditCondition.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>{
    return state;
}

const connectedEditConditionPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(EditCondition)));
export { connectedEditConditionPage as EditCondition };