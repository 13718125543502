import { userService } from '../_services/';
import history from '../_helpers/history';

export const authActions = {
    login,
    logout
};
function login(login, password){
    return dispatch => {
        let apiEndpoint = 'users/auth';
        let payload = {
            login: login,
            password: password
        }

        userService.post(apiEndpoint, payload)
            .then((response)=>{
                if (response.data.access_token) {
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('refresh_token', response.data.refresh_token);
                    localStorage.setItem('auth', response.data.auth);
                    dispatch(setUserDetails(response.data));
                    history.push('/home');
                }
            })
    };
}
function logout(){
    return dispatch => {
        localStorage.removeItem('auth');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('refresh_token');
        dispatch(logoutUser());
        history.push('/');
    }
}
export function setUserDetails(user){
    return{
        type: "LOGIN_SUCCESS",
        auth: user.auth,
        access_token: user.access_token,
        refresh_token: user.refresh_token
    }
}
export function logoutUser(){
    return{
        type: "LOGOUT_SUCCESS",
        auth: false,
        access_token: '',
        refresh_token: ''
    }
}