import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AppBar from '../_components/appbar';
import Nav from '../_components/nav';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {conditionAction, symbolAction, userAction} from "../_actions";
import moment from "moment";
import ModalBox from "./modal.component";
import CachedIcon from "@material-ui/icons/Cached";
import {showMessage} from "../_helpers";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
export * from './modal.component';


const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});
class Home extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(symbolAction.getTickerCounts());
        dispatch(conditionAction.getConditionGroups());
        dispatch(userAction.getUser());
        dispatch(conditionAction.getConditionTypes());
        dispatch(conditionAction.getConditionParams());
        dispatch(conditionAction.getConditionConditions());
//        dispatch(conditionAction.checkConditionGroup());
    }
    handleReload(){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        this.componentDidMount();
        this.render();
    }
    handleClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        dispatch(symbolAction.syncSymbolStats());
        dispatch(symbolAction.getTickerCounts());
        this.render();
    }
    handleSyncClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        dispatch(symbolAction.syncSymbolValues());
        dispatch(symbolAction.getTickerCounts());
        this.render();
    }
    handleSyncHistory(interval,from){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        if ((this.props.symbolhistory.timestamp_from) && (this.props.symbolhistory.timestamp_from > 0)) {
            from = this.props.symbolhistory.timestamp_from
        }
        dispatch(symbolAction.syncSymbolHistory(interval,from));
        dispatch(symbolAction.getTickerCounts());
        this.render();
    }
    handleChange = prop => event => {
        const { dispatch } = this.props;
        dispatch(symbolAction.onChangeProps(prop, event));
    };

    render() {
        const { classes } = this.props;
        const { match : { params } } = this.props;
        const bull = <span className={classes.bullet}>•</span>;

        const usersMap = {};
        if (this.props.user) {
            for (let value of this.props.user.user) {
                if (value.id && value.login) {
                    var key = value.id;
                    usersMap[key] = {
                        id: value.id,
                        login: value.login,
                        fname: value.fname,
                        lname: value.lname,
                        saldo: value.saldo,
                        currency: value.currency,
                        lasttime: value.lasttime,
                    };
                }
            }
        }

        function FormatTime(timestamp) {
            return moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss");
        }

        function ReturnStatusValue(statusMap,key,value) {
            if (statusMap) {
                if (statusMap[key]) {
                    return statusMap[key][value];
                }
            }
        }

        function ReturnValue (usersMap,id,param) {
            if (usersMap) {
                if (usersMap[id]) {
                    if (param === 'fname') {
                        return usersMap[id].fname
                    }
                    if (param === 'lname') {
                        return usersMap[id].lname
                    }
                    if (param === 'saldo') {
                        return usersMap[id].saldo
                    }
                    if (param === 'currency') {
                        return usersMap[id].currency
                    }
                    if (param === 'lasttime') {
                        return FormatTime(usersMap[id].lasttime)
                    }
                }
            }
        }

        console.log(this.props);

        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar/>
                    <Nav />



                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Typography variant="h4">{'Home'}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="default"
                                style={{
                                    width: '200px',
                                    backgroundColor: "#21b6ae",
                                }}
                                className={classes.button}
                                onClick={(event) => this.handleReload()}
                                endIcon={<CachedIcon>Reload</CachedIcon>}
                            >
                                Reload
                            </Button>
                                </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{width : '300px'}}
                                    className={classes.button}
                                    onClick={(event) => this.handleSyncClick(event)}
                                    endIcon={<SyncIcon>Sync Stock Quotes</SyncIcon>}
                                >
                                    Sync Stock Quotes
                                </Button><br/>
                                Last Update: {FormatTime(ReturnStatusValue(this.props.tickercount.counts,"symbol_quotes","last_ts"))}
                                <br/>
                                {ReturnStatusValue(this.props.tickercount.counts,"symbol_quotes","updated")} / {ReturnStatusValue(this.props.tickercount.counts,"symbol_quotes","total")}
                            </Grid>
                            <Grid item xs={3}>

                        <Button
                            variant="contained"
                            color="secondary"
                            style={{width : '300px'}}
                            className={classes.button}
                            onClick={(event) => this.handleClick(event)}
                            endIcon={<SyncIcon>Update Stock Stats</SyncIcon>}
                        >
                            Update Stock Stats
                        </Button><br/>
                                Last Update: {FormatTime(ReturnStatusValue(this.props.tickercount.counts,"symbol_stats","last_ts"))}
                                <br/>
                                {ReturnStatusValue(this.props.tickercount.counts,"symbol_stats","updated")} / {ReturnStatusValue(this.props.tickercount.counts,"symbol_stats","total")}
                            </Grid>
                        </Grid>

                    <Paper>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <TextField
                                    id="timestamp_from"
                                    label="TimeStamp From"
                                    className={classes.textField}
                                    value={this.props.symbolhistory.timestamp_from}
                                    onChange={this.handleChange('timestamp_from')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                        <IconButton color="primary" aria-label="check condition" onClick={(event) => this.handleSyncHistory("1m")}>
                                        <CachedIcon />
                                        </IconButton>
                                        1M
                                    </Grid>
                                    <Grid item xs={3} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                        <Typography variant="subtitle2" align={"left"}>
                                        SYN:{FormatTime(ReturnStatusValue(this.props.tickercount.counts,"history_1min","last_ts"))}
                                            <br/>
                                            AVG:{FormatTime(ReturnStatusValue(this.props.tickercount.counts,"history_1min","last_avg_ts"))}
                                        </Typography>
                                        <Typography variant="subtitle2" align={"right"}>
                                            &nbsp; &nbsp;  {ReturnStatusValue(this.props.tickercount.counts,"history_1min","updated")} / {ReturnStatusValue(this.props.tickercount.counts,"history_1min","total")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                        <IconButton color="primary" aria-label="check condition" onClick={(event) => this.handleSyncHistory("5m") }>
                                            <CachedIcon />
                                        </IconButton>
                                        5M
                                    </Grid>
                                    <Grid item xs={3} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                        <Typography variant="subtitle2" align={"left"}>
                                            SYN:{FormatTime(ReturnStatusValue(this.props.tickercount.counts,"history_5min","last_ts"))}
                                            <br/>
                                            AVG:{FormatTime(ReturnStatusValue(this.props.tickercount.counts,"history_5min","last_avg_ts"))}
                                        </Typography>
                                        <Typography variant="subtitle2" align={"right"}>
                                            &nbsp; &nbsp; {ReturnStatusValue(this.props.tickercount.counts,"history_5min","updated")} / {ReturnStatusValue(this.props.tickercount.counts,"history_5min","total")}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                        <IconButton color="primary" aria-label="check condition" onClick={(event) => this.handleSyncHistory("1d") }>
                                            <CachedIcon />
                                        </IconButton>
                                        1D
                                    </Grid>
                                    <Grid item xs={3} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                        <Typography variant="subtitle2" align={"left"}>
                                            SYN:{FormatTime(ReturnStatusValue(this.props.tickercount.counts,"history_1day","last_ts"))}
                                            <br/>
                                            AVG:{FormatTime(ReturnStatusValue(this.props.tickercount.counts,"history_1day","last_avg_ts"))}
                                        </Typography>
                                        <Typography variant="subtitle2" align={"right"}>
                                            &nbsp; &nbsp; {ReturnStatusValue(this.props.tickercount.counts,"history_1day","updated")} / {ReturnStatusValue(this.props.tickercount.counts,"history_1day","total")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>


                        <br /><br />
                        <Grid container spacing={5}>
                            {this.props.conditiongroup.user_groups.map(n => {
                                return (
                            <Grid item xs={3}>
                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    {n.user_login}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {ReturnValue(usersMap,n.user_id,'fname')}{bull}{ReturnValue(usersMap,n.user_id,'lname')}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    {ReturnValue(usersMap,n.user_id,'lasttime')}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Saldo: <b>{ReturnValue(usersMap,n.user_id,'saldo')}</b> {ReturnValue(usersMap,n.user_id,'currency')}
                                    <br />
                                    Payments:
                                    <br />
                                    Transactions:
                                    <br />
                                    Bags:
                                    <br />
                                    Conditions:

                                    <Grid container spacing={1}>
                                        {n.types.map(t => {
                                            return (
                                                <Grid item xs={6}>
                                                    <Card className={classes.root} variant="outlined">
                                                        <CardContent>
                                                            <Typography variant="body2" component="p" style={ { textTransform: "uppercase" } }>
                                                            {t.type}
                                                            </Typography>
                                                            {t.groups.map(g => {
                                                                return (
                                                                    <ModalBox param={ {group_name: g.group, amount_conditions: g.amount_conditions, user: n, condition_type: t.type, condition_type_id: t.type_id, props: this.props} }/>
                                                            );
                                                            })}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                                        </Grid>


                                </Typography>
                            </CardContent>

                        </Card>
                        </Grid>
                                );
                            })}
                        </Grid>



                    </main>
                </div>
            </div>
        );
    }
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>{
    return state;
}

const connectedHomePage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Home)));
export { connectedHomePage as Home };