const initialState = {
    anchor: 'left',
    resolutions: [],
    key: "",
    value: "",
    open: false,
};

export function conditionresolution(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITIONRESOLUTIONS':
            return {
                ...state,
                resolutions: action.resolutions
            };

        default:
            return state
    }
}