import { paymentService } from '../_services/';
import history from '../_helpers/history';

export const paymentAction = {
    getPayments,
    onChangeProps,
    createPayment,
};
function getPayments(){
    return dispatch => {
        let apiEndpoint = 'payments';
        paymentService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changePaymentsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function createPayment(payload){
    return dispatch => {
        let apiEndpoint = 'payments/create';
        paymentService.post(apiEndpoint, payload)
            .then((response)=>{
                dispatch(createPaymentInfo());
                history.push('/payments');
            })
    }
}

function onChangeProps(props, event){
    return dispatch =>{
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}

export function changePaymentsList(payment){
    return{
        type: "FETCHED_ALL_PAYMENT",
        payment: payment
    }
}
export function handleOnChangeProps(props, value){
    return{
        type: "HANDLE_ON_CHANGE",
        props: props,
        value: value
    }
}

export function createPaymentInfo(){
    return{
        type: "PAYMENT_CREATED_SUCCESSFULLY"
    }
}
