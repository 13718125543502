import { combineReducers } from 'redux';
import { authentication } from './auth.reducer';
import { user } from './user.reducer';
import { payment } from './payment.reducer';
import { symbol } from './symbol.reducer';
import { symbolhistory } from './symbolhistory.reducer';
import { country } from './country.reducer';
import { sector } from './sector.reducer';
import { industry } from './industry.reducer';
import { tickercount } from './tickercount.reducer';
import { condition } from './condition.reducer';
import { conditiontype } from './conditiontype.reducer';
import { conditionparam } from './conditionparam.reducer';
import { conditioncondition } from './conditioncondition.reducer';
import { conditionresolution } from './conditionresolution.reducer';
import { conditiongroup } from './conditiongroup.reducer';
import { conditioncheck } from './conditioncheck.reducer';
import { symbolhistorystatus } from "./symbolhistorystatus.reducer";

const rootReducer = combineReducers({
    authentication,
    user,
    payment,
    symbol,
    symbolhistory,
    symbolhistorystatus,
    country,
    sector,
    industry,
    tickercount,
    condition,
    conditiontype,
    conditionparam,
    conditioncondition,
    conditionresolution,
    conditiongroup,
    conditioncheck,
});
export default rootReducer;