import { conditionService } from '../_services/';
import {showMessage} from '../_helpers/notify';

export const conditionAction = {
    getCondition,
    getConditionById,
    initCondition,
    onChangeProps,
    editConditionInfo,
    createCondition,
    deleteConditionById,
    getConditionTypes,
    getConditionParams,
    getConditionConditions,
    getConditionResolutions,
    getConditionGroups,
    checkConditionGroup,
};
function getCondition(){
    return dispatch => {
        let apiEndpoint = 'conditions';
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function createCondition(payload){
    return dispatch => {
        let apiEndpoint = 'conditions/create';
        conditionService.post(apiEndpoint, payload)
            .then((response)=>{
                dispatch(createConditionInfo());
//                history.push('/conditions?show_message_type=success&show_message=Condition created successfully');
                showMessage("success",'Condition created successfully');
            }).catch((err)=>{
                console.log(err);
                showMessage("error",'Error when creating Condition');
        })
    };
}
function getConditionById(id){
    return dispatch => {
        let apiEndpoint = 'conditions/'+ id;
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(editConditionDetails(response.data));
            })
    };
}
function initCondition (id){
    return dispatch => {
        dispatch(initConditionDetails());
    }
}
function onChangeProps(props, event){
    return dispatch =>{
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
function editConditionInfo(id, payload){
    return dispatch => {
        let apiEndpoint = 'conditions/'+ id;
        conditionService.put(apiEndpoint, payload)
            .then((response)=>{
                dispatch(updatedConditionInfo());
                showMessage("success",'Condition edited successfully');
//                history.push('/conditions');
            }).catch((err)=>{
                console.log(err);
                showMessage("error",'Error when creating Condition');
        })
    };
}
function deleteConditionById(id){
    return dispatch => {
        let apiEndpoint = 'conditions/'+ id;
        conditionService.deleteDetail(apiEndpoint)
            .then((response)=>{
                dispatch(deleteConditionDetails());
                dispatch(conditionAction.getCondition());
            }).catch((err)=>{
                console.log(err);
                showMessage("error",'Error when creating Condition');
        })
    };
}
function getConditionTypes(){
    return dispatch => {
        let apiEndpoint = 'conditions/types';
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionTypesList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function getConditionParams(){
    return dispatch => {
        let apiEndpoint = 'conditions/params';
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionParamsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function getConditionConditions(){
    return dispatch => {
        let apiEndpoint = 'conditions/conditions';
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionConditionsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function getConditionResolutions(){
    return dispatch => {
        let apiEndpoint = 'conditions/resolutions';
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionResolutionsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
function getConditionGroups(){
    return dispatch => {
        let apiEndpoint = 'conditions/groups';
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionGroupsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function checkConditionGroup(user_id, condition_type, condition_group) {
    return dispatch => {
        let apiEndpoint = 'conditions/check?user_id='+user_id+'&condition_type='+condition_type+'&condition_group='+condition_group;
        conditionService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeConditionGroupCheck(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}
export function changeConditionsList(condition){
    return{
        type: "FETCHED_ALL_CONDITION",
        condition: condition
    }
}
export function handleOnChangeProps(props, value){
    return{
        type: "HANDLE_ON_CHANGE",
        props: props,
        value: value
    }
}

export function editConditionDetails(condition){
    return{
        type: "CONDITION_DETAIL",
        id: condition.id,
        factor: condition.factor,
        active: condition.active,
        last_rule: condition.last_rule,
        group: condition.group,
        priority: condition.priority,
        user_id: condition.user_id,
        condition_type: condition.type,
        param: condition.param,
        condition_symbol: condition.condition_symbol,
        target: condition.target,
        target_resolution: condition.target_resolution,
        target_from: condition.target_from,
        target_to: condition.target_to,
        value: condition.value,
        utime: condition.utime,
        ctime: condition.ctime
    }
}
export function initConditionDetails(){
    return{
        type: "CONDITION_DETAIL",
        id: "",
        factor: 1,
        active: true,
        last_rule: false,
        group: "",
        priority: 1,
        user_id: "",
        condition_type: 0,
        param: "",
        condition_symbol: 0,
        target: "",
        target_resolution: "",
        target_from: 0,
        target_to: 0,
        value: "",
    }
}

export function updatedConditionInfo(){
    return{
        type: "CONDITION_UPDATED"
    }
}
export function createConditionInfo(){
    return{
        type: "CONDITION_CREATED_SUCCESSFULLY"
    }
}
export function deleteConditionDetails(){
    return{
        type: "DELETED_CONDITION_DETAILS"
    }
}
export function changeConditionTypesList(types){
    return{
        type: "FETCHED_ALL_CONDITIONTYPES",
        types: types
    }
}
export function changeConditionParamsList(params){
    return{
        type: "FETCHED_ALL_CONDITIONPARAMS",
        params: params
    }
}
export function changeConditionConditionsList(conditions){
    return{
        type: "FETCHED_ALL_CONDITIONCONDITIONS",
        conditions: conditions
    }
}
export function changeConditionResolutionsList(resolutions){
    return{
        type: "FETCHED_ALL_CONDITIONRESOLUTIONS",
        resolutions: resolutions
    }
}
export function changeConditionGroupsList(user_groups){
    return{
        type: "FETCHED_ALL_CONDITIONGROUPS",
        user_groups: user_groups
    }
}
export function changeConditionGroupCheck(check_data){
    return{
        type: "FETCHED_ALL_CONDITIONGROUPCHECK",
        conditions: check_data.conditions,
        symbols: check_data.symbols,
        amount: check_data.amount,
        sum: check_data.sum,
        currency: check_data.currency,
    }
}