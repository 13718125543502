import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AppBar from '../_components/appbar';
import Nav from '../_components/nav';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {conditionAction, symbolAction, userAction} from "../_actions";
import moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";
import IconButton from "@material-ui/core/IconButton";
import {Paper} from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChartComponent from "./chart.component";
import './symbol.component.css'


const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});

let prevCPrice = 0;
const ws = new WebSocket('wss://ws.markquotes.com');

class SymbolView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: null,
            lastprice: 0
        };
    }

    componentWillMount() {

        let old = 0;
        const { match : { params } } = this.props;
        const symbol = params.id;
        const apiCall = {
            event: 'subscribe',
            data: { channel: symbol },
        };

        function updatePrevCPrice(value) {
            prevCPrice = value;
            return true
        }

        ws.onopen = () => {
            ws.send(JSON.stringify(apiCall));
            console.log('WebSocket Client Connected');
        };
        ws.onmessage = (event) => {
            const json = JSON.parse(event.data);

            try {
                if (json.symbol == symbol) {
                    console.log(symbol);
                    if (updatePrevCPrice(old)) {
                            this.setState({
                                lastprice: json.cprice
                            });
                            old = json.cprice;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };
    }

    componentWillUnmount() {
        //clean up function
        prevCPrice = 0;
        clearInterval(this.interval);
        ws.close();
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { match : { params } } = this.props;
        dispatch(userAction.getUser());
        dispatch(symbolAction.getSymbolById(params.id));
        dispatch(symbolAction.getTickerCounts());
        dispatch(symbolAction.getSymbolSyncStatusById(params.id));
//        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        // Reload page every 30s
        this.interval = setInterval(() => this.handleReload(), 30000);
    }
    componentDidUpdate() {
        const { dispatch } = this.props;
        const { match : { params } } = this.props;
        if (params.id && this.props.symbolhistorystatus && !this.state.interval) {
            if (this.props.symbolhistorystatus.last_1min_live_ts) {
                dispatch(symbolAction.getSymbolHistoryById(params.id,"1m_live",this.props.symbolhistorystatus.last_1min_live_ts-3600*3));
                this.setState({"interval": "3h_live"});
            }
        }
    }
    handleReload(){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        this.componentDidMount();
        if (params.id && this.props.symbolhistorystatus && this.state.interval) {
            if (this.state.interval === "1d") {
                if (this.props.symbolhistorystatus.last_1min_ts) {
                    // 12 * 6  = 72
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1m", this.props.symbolhistorystatus.last_1min_ts - 86400));
                }
            }
            if (this.state.interval === "1w") {
                if (this.props.symbolhistorystatus.last_5min_ts) {
                    // 4 * 6 * 5 = 120
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "5m", this.props.symbolhistorystatus.last_5min_ts - (86400 * 7)));
                }
            }
            if (this.state.interval === "1m") {
                if (this.props.symbolhistorystatus.last_5min_ts) {
                    // 1 * 6 * 22 = 132
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "5m", this.props.symbolhistorystatus.last_5min_ts - (86400 * 30)));
                }
            }
            if (this.state.interval === "1q") {
                if (this.props.symbolhistorystatus.last_1day_ts) {
                    // 1 * 6 * 22 * 3 = 396
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1d", this.props.symbolhistorystatus.last_1day_ts - (86400 * 30 * 3)));
                }
            }
            if (this.state.interval === "1y") {
                if (this.props.symbolhistorystatus.last_1day_ts) {
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1d", this.props.symbolhistorystatus.last_1day_ts - (86400 * 30 * 12)));
                }
            }
            if (this.state.interval === "5y") {
                if (this.props.symbolhistorystatus.last_1day_ts) {
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1d", this.props.symbolhistorystatus.last_1day_ts - (86400 * 30 * 12 * 5)));
                }
            }
            // Live данные
            if (this.state.interval === "3h_live") {
                if (this.props.symbolhistorystatus.last_1min_live_ts) {
                    // 60 * 3  = 180
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1m_live", this.props.symbolhistorystatus.last_1min_live_ts - 3600*3));
                }
            }
            if (this.state.interval === "1d_live") {
                if (this.props.symbolhistorystatus.last_5min_live_ts) {
                    // 12 * 6  = 72
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "5m_live", this.props.symbolhistorystatus.last_5min_live_ts - 86400));
                }
            }
            if (this.state.interval === "1w_live") {
                if (this.props.symbolhistorystatus.last_15min_live_ts) {
                    // 4 * 6 * 5 = 120
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "15m_live", this.props.symbolhistorystatus.last_15min_live_ts - (86400 * 7)));
                }
            }
            if (this.state.interval === "1m_live") {
                if (this.props.symbolhistorystatus.last_1hour_live_ts) {
                    // 1 * 6 * 22 = 132
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1h_live", this.props.symbolhistorystatus.last_1hour_live_ts - (86400 * 30)));
                }
            }
            if (this.state.interval === "1q_live") {
                if (this.props.symbolhistorystatus.last_1hour_live_ts) {
                    // 1 * 6 * 22 * 3 = 396
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1h_live", this.props.symbolhistorystatus.last_1hour_live_ts - (86400 * 30 * 3)));
                }
            }
            if (this.state.interval === "1y_live") {
                if (this.props.symbolhistorystatus.last_1day_live_ts) {
                    // 365
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1d_live", this.props.symbolhistorystatus.last_1day_live_ts - (86400 * 30 * 12)));
                }
            }
            if (this.state.interval === "5y_live") {
                if (this.props.symbolhistorystatus.last_1day_live_ts) {
                    // 365 * 5
                    dispatch(symbolAction.getSymbolHistoryById(params.id, "1d_live", this.props.symbolhistorystatus.last_1day_live_ts - (86400 * 30 * 12 * 5)));
                }
            }
        }
        //this.render();
    }
    handleRenewChart(interval) {
        const {match: {params}} = this.props;
        const {dispatch} = this.props;

        this.setState({"interval": interval}, function () {
            this.handleReload();
        });
    }
    handleClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        dispatch(symbolAction.syncSymbolStats());
        dispatch(symbolAction.getTickerCounts());
        //this.render();
    }
    handleSyncClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        dispatch(symbolAction.syncSymbolValues());
        dispatch(symbolAction.getTickerCounts());
        //this.render();
    }
    handleSyncHistory(interval,from){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        dispatch(symbolAction.syncSymbolHistory(interval,from));
        dispatch(symbolAction.getTickerCounts());
        //this.render();
    }

    render() {
        const { classes } = this.props;
        const { match : { params } } = this.props;
        const symbol = this.props.symbol.symbol;

        const usersMap = {};
        if (this.props.user) {
            for (let value of this.props.user.user) {
                if (value.id && value.login) {
                    var key = value.id;
                    usersMap[key] = {
                        id: value.id,
                        login: value.login,
                        fname: value.fname,
                        lname: value.lname,
                        saldo: value.saldo,
                        currency: value.currency,
                        lasttime: value.lasttime,
                    };
                }
            }
        }

        function FormatTime(timestamp) {
            return moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss");
        }

        function abbreviateNumber(value) {
            var newValue = value;
            if (value >= 1000) {
                var suffixes = ["", "K", "M", "B","T","Q"];
                var suffixNum = Math.floor( (""+value).length/3 );
                var shortValue = '';
                for (var precision = 3; precision >= 1; precision--) {
                    shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                    var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                    if (dotLessShortValue.length <= 3) { break; }
                }
                if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
                newValue = shortValue+suffixes[suffixNum];
            }
            return newValue;
        }

        function ReturnStatusValue(statusMap,key,value) {
            if (statusMap) {
                if (statusMap[key]) {
                    return statusMap[key][value];
                }
            }
        }
        function getWidth (param) {
            if (document.getElementById(param)) {
                return document.getElementById(param).offsetWidth
            }
            return 0
        }

        function roundDown(number, decimals) {
            decimals = decimals || 0;
            return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
        }

        function renderCprice(cprice, oprice, prevPrice, lastPrice) {
            if (lastPrice && lastPrice !=0) {
                cprice = lastPrice
            }
            if (cprice < oprice) {
                var diff = roundDown(oprice - cprice,2)
                var diff_percent = roundDown((oprice - cprice)*100/oprice,2)
                return (
                    <Typography variant="h4">
                        <div className={isPriceChange(lastPrice,prevPrice)}><font style={{color: 'red'}}>{roundDown(cprice,2)}</font><ArrowDropDownIcon style={{ color: "red" }} fontSize="large" />
                            <font style={{ color: "red", fontSize: "medium" }}>-{diff} ({diff_percent}%)</font></div>
                    </Typography>
                )
            } else if (cprice > oprice) {
                var diff = roundDown(Math.floor(cprice - oprice,3),2)
                var diff_percent = roundDown((cprice - oprice)*100/oprice,2)
                return (
                    <Typography variant="h4">
                        <div className={isPriceChange(lastPrice,prevPrice)}><font style={{ color: 'green'}}>{roundDown(cprice,2)}</font><ArrowDropUpIcon style={{ color: "green" }} fontSize="large" />
                            <font style={{ color: "green", fontSize: "medium" }}>+{diff} ({diff_percent}%)</font></div>
                    </Typography>
                )
            } else {
                return (
                    <Typography variant="h4">
                    {cprice}
                    </Typography>
                )
            }
        }

        function renderColor(value, percent) {
            if (value < 0 || percent<0) {
                return (
                    <div style={{backgroundColor:'red', color: 'white',fontWeight: 'bold', display:'inline-block'}}>{roundDown(value,2)} ({roundDown(percent,2)}%)</div>
                )
            } else if (value > 0 || percent>0) {
                return (
                        <div style={{backgroundColor:'green', color: 'white',fontWeight: 'bold', display:'inline-block'}}>{roundDown(value,2)} ({roundDown(percent,2)}%)</div>
                )
            } else {
                return (
                        <div style={{backgroundColor:'lightgray', color: 'black', fontWeight: 'bold', display:'inline-block'}}>{roundDown(value,2)} ({roundDown(percent,2)}%)</div>
                )
            }
        }

        function isPriceChange(new_val,old_val) {
            if (new_val !== old_val) {
                return ''
            }
//            return 'changePrice'
            return ''
        }

        console.log(this.props);

        let calculatedData = new Object();
        let calc1ml = new Object();
        let calc5ml = new Object();
        let calc15ml = new Object();
        let calc1hl = new Object();
        let calc1dl = new Object();
        if (this.props.symbol.indicators) {
            if (this.props.symbol.indicators.calculated) {
                calculatedData = this.props.symbol.indicators.calculated
                calc1ml = calculatedData['1m_live']
                calc5ml = calculatedData['5m_live']
                calc15ml = calculatedData['15m_live']
                calc1hl = calculatedData['1h_live']
                calc1dl = calculatedData['1d_live']
            }
        }

        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar/>
                    <Nav />



                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        <Grid container spacing={5}>
                            <Grid item xs={1} style={{display: 'flex', alignItems:"center",justifyContent:"center"}}>
                                <img src={'/static/media/logos/'+params.id+'.png'} width={50} height={50}></img>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="h4">{params.id}</Typography>
                                <Typography variant="subtitle1">{symbol.name}</Typography>
                                <Typography variant="subtitle2">MarketCap <b>{abbreviateNumber(symbol.marketcap)}</b> {symbol.currency}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {renderCprice(symbol.cprice,symbol.oprice,prevCPrice,this.state.lastprice)}
                                <Typography variant="subtitle1">O:<b>{roundDown(symbol.oprice,2)}</b> H:<b>{roundDown(symbol.hprice,2)}</b> L:<b>{roundDown(symbol.lprice,2)}</b></Typography>
                                <Typography variant="subtitle2">Volume: <b>{abbreviateNumber(symbol.volume)}</b> {symbol.currency}</Typography>
                                </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">{symbol.sector}</Typography>
                                <Typography variant="subtitle2">{symbol.industry}</Typography>
                                <Typography variant="subtitle2">{symbol.country}</Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Paper>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                <Typography variant="subtitle2" align={"left"}>
                                    <IconButton color="primary" aria-label="check condition" size={"small"} onClick={(event) => this.handleClick(event)}>
                                        <CachedIcon />
                                    </IconButton>
                                    Statistic: {FormatTime(symbol.statstime)}
                                </Typography>
                                <Typography variant="subtitle2" align={"left"}>
                                    <IconButton color="primary" aria-label="check condition" size={"small"} onClick={(event) => this.handleSyncClick(event)}>
                                        <CachedIcon />
                                    </IconButton>
                                    Quotes: {FormatTime(symbol.synctime)}
                                </Typography>

                                <Typography variant="subtitle2" align={"left"}>
                                    <IconButton color="primary" aria-label="check condition" size={"small"} onClick={(event) => this.handleSyncHistory("1m") }>
                                        <CachedIcon />
                                    </IconButton>
                                    Chart: {FormatTime(this.props.symbolhistorystatus.last_1min_live_ts)}
                                </Typography>
                                        </Grid>

                                        <Grid item xs={4}>

                                            <Button
                                                variant="contained"
                                                color="default"
                                                style={{
                                                    width: '100px',
                                                    backgroundColor: "#21b6ae",
                                                }}
                                                className={classes.button}
                                                onClick={(event) => this.handleReload()}
                                                endIcon={<CachedIcon>Reload</CachedIcon>}
                                            >
                                                Reload
                                            </Button>

                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>



                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Paper id = "chartcomponent">
                                    <div align={"center"}>
                                        Zoom History: <a href={"#"} onClick={(event) => this.handleRenewChart("1d")}>1D</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1w") }>1W</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1m") }>1M</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1q") }>1Q</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1y") }>1Y</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("5y") }>5Y</a>
                                        <br/>
                                        Zoom Live: <a href={"#"} onClick={(event) => this.handleRenewChart("3h_live")}>3H</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1d_live")}>1D</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1w_live") }>1W</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1m_live") }>1M</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1q_live") }>1Q</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("1y_live") }>1Y</a>, <a href={"#"} onClick={(event) => this.handleRenewChart("5y_live") }>5Y</a>
                                    </div>
                                    <div align={"left"}>
                                        <ChartComponent param={{width: getWidth("chartcomponent"), hData: this.props.symbolhistory.history, symbol:this.props.symbolhistory.symbol}} />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <Grid container spacing={5}>
                                        <Grid item xs={3}>
                                    <Table className={classes.table}>
                                        <TableRow>
                                                <TableCell>IPO Date</TableCell>
                                                <TableCell><b>{this.props.symbol.symbol.ipo}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Forward/Trailing EPS</TableCell>
                                            <TableCell><b>{this.props.symbol.symbol.forward_eps} / {roundDown(this.props.symbol.symbol.trailing_eps,2)}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Forward P/E</TableCell>
                                            <TableCell><b>{roundDown(this.props.symbol.symbol.forward_pe,2)}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>PEG Ratio</TableCell>
                                            <TableCell><b>{this.props.symbol.symbol.peg_ratio}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Price to book</TableCell>
                                            <TableCell><b>{roundDown(this.props.symbol.symbol.price_to_book,2)}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Profit Margin</TableCell>
                                            <TableCell><b>{roundDown(this.props.symbol.symbol.profit_margin*100,2)}%</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Debt / Equity (%)</TableCell>
                                            <TableCell><b>%</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>ROI (%) / ROA (%)</TableCell>
                                            <TableCell><b>% / %</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Ebitda</TableCell>
                                            <TableCell><b></b></TableCell>
                                        </TableRow>
                                         </Table>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Table className={classes.table}>
                                                <TableRow>
                                                    <TableCell>Perf Day</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_day, this.props.symbol.indicators.perf_day_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Perf Week</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_week, this.props.symbol.indicators.perf_week_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Perf Month</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_month, this.props.symbol.indicators.perf_month_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Perf Quarter</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_quarter, this.props.symbol.indicators.perf_quarter_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Perf Half-Year</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_half_year, this.props.symbol.indicators.perf_half_year_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Perf Year</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_year, this.props.symbol.indicators.perf_year_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Perf 5-Year</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.perf_five_year, this.props.symbol.indicators.perf_five_year_percent)}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                <TableCell>Change 52W</TableCell>
                                                <TableCell><b>{roundDown(this.props.symbol.indicators.change_52_weeks_percent,2)} %</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>52W High/Low</TableCell>
                                                    <TableCell><b>{renderColor(this.props.symbol.indicators.high_52_weeks, this.props.symbol.indicators.high_52_weeks_percent)} / {renderColor(this.props.symbol.indicators.low_52_weeks, this.props.symbol.indicators.low_52_weeks_percent)}</b></TableCell>
                                                </TableRow>
                                            </Table>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Table className={classes.table}>
                                                <TableRow>
                                                    <TableCell>ATR (14)</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.atr_14,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.atr_14,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.atr_14,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.atr_14,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.atr_14,2)}</b>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>SMA20</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.sma_20,2)}</b><br/>
                                                                <b>{roundDown(calc1ml.sma_20_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.sma_20,2)}</b><br/>
                                                                <b>{roundDown(calc5ml.sma_20_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.sma_20,2)}</b><br/>
                                                                <b>{roundDown(calc15ml.sma_20_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.sma_20,2)}</b><br/>
                                                                <b>{roundDown(calc1hl.sma_20_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.sma_20,2)}</b><br/>
                                                                <b>{roundDown(calc1dl.sma_20_percent,2)}</b>%
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>SMA50</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.sma_50,2)}</b><br/>
                                                                <b>{roundDown(calc1ml.sma_50_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.sma_50,2)}</b><br/>
                                                                <b>{roundDown(calc5ml.sma_50_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.sma_50,2)}</b><br/>
                                                                <b>{roundDown(calc15ml.sma_50_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.sma_50,2)}</b><br/>
                                                                <b>{roundDown(calc1hl.sma_50_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.sma_50,2)}</b><br/>
                                                                <b>{roundDown(calc1dl.sma_50_percent,2)}</b>%
                                                            </Grid>
                                                        </Grid>

                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>SMA200</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.sma_200,2)}</b><br/>
                                                                <b>{roundDown(calc1ml.sma_200_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.sma_200,2)}</b><br/>
                                                                <b>{roundDown(calc5ml.sma_200_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.sma_200,2)}</b><br/>
                                                                <b>{roundDown(calc15ml.sma_200_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.sma_200,2)}</b><br/>
                                                                <b>{roundDown(calc1hl.sma_200_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.sma_200,2)}</b><br/>
                                                                <b>{roundDown(calc1dl.sma_200_percent,2)}</b>%
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>EMA12</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.ema_12,2)}</b><br/>
                                                                <b>{roundDown(calc1ml.ema_12_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.ema_12,2)}</b><br/>
                                                                <b>{roundDown(calc5ml.ema_12_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.ema_12,2)}</b><br/>
                                                                <b>{roundDown(calc15ml.ema_12_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.ema_12,2)}</b><br/>
                                                                <b>{roundDown(calc1hl.ema_12_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.ema_12,2)}</b><br/>
                                                                <b>{roundDown(calc1dl.ema_12_percent,2)}</b>%
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>EMA26</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.ema_26,2)}</b><br/>
                                                                <b>{roundDown(calc1ml.ema_26_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.ema_26,2)}</b><br/>
                                                                <b>{roundDown(calc5ml.ema_26_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.ema_26,2)}</b><br/>
                                                                <b>{roundDown(calc15ml.ema_26_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.ema_26,2)}</b><br/>
                                                                <b>{roundDown(calc1hl.ema_26_percent,2)}</b>%
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.ema_26,2)}</b><br/>
                                                                <b>{roundDown(calc1dl.ema_26_percent,2)}</b>%
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>MACD(12,26)</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1ml.macd_1226,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc5ml.macd_1226,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc15ml.macd_1226,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1hl.macd_1226,2)}</b>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <b>{roundDown(calc1dl.macd_1226,2)}</b>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Table className={classes.table}>
                                                <TableRow>
                                                    <TableCell>Bollinger High</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.forward_eps}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Bollinger Low</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.trailing_eps}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Support/Resistance</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.forward_pe}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Bulls Power / Bears Power</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.forward_pe}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>RSI</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.forward_pe}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Stochastic</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.forward_pe}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Trend Line</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.forward_pe}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Tech Analysis</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.peg_ratio}</b></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Recommendation</TableCell>
                                                    <TableCell><b>{this.props.symbol.symbol.price_to_book}</b></TableCell>
                                                </TableRow>

                                            </Table>
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>



                    </main>
                </div>
            </div>
        );
    }
}
SymbolView.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>{
    return state;
}

const connectedHomePage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(SymbolView)));
export { connectedHomePage as SymbolView };
