const initialState = {
    anchor: 'left',
    params: [],
    key: "",
    value: "",
    open: false,
};

export function conditionparam(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITIONPARAMS':
            return {
                ...state,
                params: action.params
            };

        default:
            return state
    }
}