const initialState = {
    anchor: 'left',
    counts: [],
    timestamp: 0,
    open: false,
};

export function tickercount(state = initialState, action) {
    switch (action.type) {
        case 'COUNT_DETAIL':
            return {
                ...state,
                counts: action.counts,
                timestamp: action.timestamp,
            };

        default:
            return state
    }
}