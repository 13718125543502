const initialState = {
    anchor: 'left',
    user_groups: [],
    key: "",
    value: 0,
    open: false,
};

export function conditiongroup(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITIONGROUPS':
            return {
                ...state,
                user_groups: action.user_groups
            };

        default:
            return state
    }
}