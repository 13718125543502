const initialState = {
    anchor: 'left',
    conditions: [],
    key: "",
    value: 0,
    open: false,
};

export function conditioncondition(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITIONCONDITIONS':
            return {
                ...state,
                conditions: action.conditions
            };

        default:
            return state
    }
}