const initialState = {
    anchor: 'left',
    country: [],
    open: false,
};

export function country(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_COUNTRIES':
            return {
                ...state,
                country: action.country
            };

        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}