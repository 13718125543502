import { symbolService } from '../_services/';
import {showMessage} from "../_helpers";

export const symbolAction = {
    getSymbols,
    getSymbolById,
    getSymbolHistoryById,
    getSymbolSyncStatusById,
    onChangeProps,
    onChangePropsValues,
    getCountries,
    getSectors,
    getIndustries,
    getTickerCounts,
    syncSymbolProfiles,
    syncSymbolValues,
    syncSymbolStats,
    syncSymbolHistory,
};

function getSymbols(orderBy,order,filters,q){
    return dispatch => {
        let apiEndpoint = 'stock/symbols?orderby='+orderBy+'&order='+order+'&q='+q+'&filters='+filters;
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeSymbolsList(response.data, orderBy, order));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function getSymbolById(id){
    return dispatch => {
        let apiEndpoint = 'stock/symbols/'+id;
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(editSymbolDetails(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function getSymbolHistoryById(id,interval,from,to){
    return dispatch => {
        let apiEndpoint = 'stock/symbols/'+id +'/chart?interval='+interval+'&from='+from+'&to='+to;
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeHistoryList(response.data,id,interval));
            }).catch((err)=>{
            console.log(err);
            showMessage("error",'Error when Getting Symbol History');
        })
    };
}

function getSymbolSyncStatusById(id){
    return dispatch => {
        let apiEndpoint = 'stock/symbols/'+id +'/historyts';
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(editSymbolSyncStatusDetails(response.data,id));
            }).catch((err)=>{
            console.log(err);
            showMessage("error",'Error when Getting Sync Status');
        })
    };
}

function getCountries(){
    return dispatch => {
        let apiEndpoint = 'stock/countries';
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeCountriesList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function getSectors(country){
    return dispatch => {
        let apiEndpoint = 'stock/sectors?country='+country;
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeSectorsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function getIndustries(country,sector){
    return dispatch => {
        let apiEndpoint = 'stock/industries?country='+country+'&sector='+sector;
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(changeIndustriesList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function getTickerCounts(){
    return dispatch => {
        let apiEndpoint = 'stock/symbolcounts';
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(editCountDetails(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function syncSymbolProfiles (){
    return dispatch => {
        let apiEndpoint = 'stock/syncsymbolprofiles2';
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(defaultSymbolsList(response.data));
            }).catch((err)=>{
            console.log(err);
        })
    };
}

function syncSymbolValues (){
    return dispatch => {
        let apiEndpoint = 'stock/syncsymbols';
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(defaultSymbolsList(response.data));
                showMessage("success",'Syncing stock values started successfully');
            }).catch((err)=>{
                console.log(err);
                showMessage("error",'Error when Syncing stock values');
        })
    };
}

function syncSymbolStats (){
    return dispatch => {
        let apiEndpoint = 'stock/syncsymbolstats';
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(defaultSymbolsList(response.data));
                showMessage("success",'Syncing stock statistics started successfully');
            }).catch((err)=>{
            console.log(err);
            showMessage("error",'Error when Syncing stock Statistics');
        })
    };
}

function syncSymbolHistory (interval,from){
    return dispatch => {
        let apiEndpoint = 'stock/syncsymbolhistory?interval='+interval+'&from='+from;
        symbolService.get(apiEndpoint)
            .then((response)=>{
                dispatch(defaultSymbolsList(response.data));
                showMessage("success",'Syncing stock historical data started successfully');
            }).catch((err)=>{
            console.log(err);
            showMessage("error",'Error when Syncing stock historical data');
        })
    };
}

function onChangeProps(props, event){
    return dispatch =>{
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}

function onChangePropsValues(props, value){
    return dispatch =>{
        dispatch(handleOnChangeProps(props, value));
    }
}

export function changeSymbolsList(symbol, orderBy, order, filters){
    return{
        type: "FETCHED_ALL_SYMBOL",
        symbol: symbol,
        orderby: orderBy,
        order: order,
        filters: filters,
    }
}
export function handleOnChangeProps(props, value){
    return{
        type: "HANDLE_ON_CHANGE",
        props: props,
        value: value
    }
}

export function changeCountriesList(country){
    return{
        type: "FETCHED_ALL_COUNTRIES",
        country: country
    }
}

export function changeSectorsList(sector){
    return{
        type: "FETCHED_ALL_SECTORS",
        sector: sector
    }
}

export function changeIndustriesList(industry){
    return{
        type: "FETCHED_ALL_INDUSTRIES",
        industry: industry
    }
}

export function changeHistoryList(history,symbol,interval){
    return{
        type: "FETCHED_ALL_HiSTORY",
        history: history,
        symbol: symbol,
        interval: interval
    }
}

export function defaultSymbolsList(result){
    return{
        type: "FETCHED_DEFAULT",
        result: result,
    }
}

export function editSymbolDetails(symbol){
    return{
        type: "SYMBOL_DETAIL",
        symbol: symbol,
    }
}

export function editCountDetails(count){
    return{
        type: "COUNT_DETAIL",
        counts: count.counts,
        timestamp: count.timestamp,
    }
}

export function editSymbolSyncStatusDetails(syncstatus, id){
    return{
        type: "SYMBOL_SYNC_STATUS_DETAIL",
        symbol: id,
        last_1min_ts: syncstatus.last_1min_ts,
        last_5min_ts: syncstatus.last_5min_ts,
        last_1day_ts: syncstatus.last_1day_ts,
        last_1min_live_ts: syncstatus.last_1min_live_ts,
        last_5min_live_ts: syncstatus.last_5min_live_ts,
        last_15min_live_ts: syncstatus.last_15min_live_ts,
        last_1hour_live_ts: syncstatus.last_1hour_live_ts,
        last_1day_live_ts: syncstatus.last_1day_live_ts,
    }
}