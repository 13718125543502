import React from "react";
import { format } from "d3-format";
import moment from "moment";
import {
    elderRay,
    ema,
    sma,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    CandlestickSeries,
    ElderRaySeries,
    LineSeries,
    MovingAverageTooltip,
    OHLCTooltip,
    SingleValueTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    head,
    withDeviceRatio,
    withSize
} from "react-financial-charts";
import { utcDay, utcMinute, utcHour } from "d3-time";

export default function ChartComponent(param) {
    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );

    const height = 500;
    const width = param.param.width;
    const margin = { left: 0, right: 48, top: 0, bottom: 24 };

    const ema12 = ema()
        .id(1)
        .options({ windowSize: 12 })
        .merge((d, c) => {
            d.ema12 = c;
        })
        .accessor((d) => d.ema12);

    const ema26 = ema()
        .id(2)
        .options({ windowSize: 26 })
        .merge((d, c) => {
            d.ema26 = c;
        })
        .accessor((d) => d.ema26);

    const sma20 = sma()
        .id(3)
        .options({ windowSize: 20 })
        .merge((d, c) => {
            d.sma20 = c;
        })
        .accessor((d) => d.sma20);

    const sma50 = sma()
        .id(4)
        .options({ windowSize: 50 })
        .merge((d, c) => {
            d.sma50 = c;
        })
        .accessor((d) => d.sma50);

    const sma200 = sma()
        .id(5)
        .options({ windowSize: 200 })
        .merge((d, c) => {
            d.sma200 = c;
        })
        .accessor((d) => d.sma200);

    const elder = elderRay();

    var initialData = [];

    if (param.param.hData) {
        for (let value of param.param.hData) {
            if (value.timestamp && value.symbol) {
                var eObject  = {
                    name: value.symbol,
                    date: moment.unix(value.timestamp).format(),
                    open: value.oprice,
                    close: value.cprice,
                    high: value.hprice,
                    low: value.lprice,
                    volume: value.volume,
                };
                initialData.push(eObject);
            }
        }
    }

//    console.log(initialData.length);

    const calculatedData = elder(ema26(ema12(initialData)));
    const calculatedDataSMA = sma200(sma50(sma20(initialData)));
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        initialData
    );

    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    // если поломается график - то нужно в строчке ниже исправить data.length - 100
    const min = xAccessor(data[Math.max(0, data.length - 1)]);
//    const xExtents = [min, max + 5];
    const xExtents = [0, max + 3];

    const gridHeight = height - margin.top - margin.bottom;

    const elderRayHeight = 100;
    const elderRayOrigin = (_, h) => [0, h - elderRayHeight];
    const barChartHeight = gridHeight / 4;
    const barChartOrigin = (_, h) => [0, h - barChartHeight - elderRayHeight];
    const chartHeight = gridHeight - elderRayHeight;
    const yExtents = (data) => {
        return [data.high, data.low];
    };

    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high, data.low];
    };

    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data) => {
        return data.volume;
    };

    const openCloseColor = (data) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };

    return (
        <ChartCanvas
            height={height}
            ratio={3}
            width={width}
            margin={margin}
            data={data}
            displayXAccessor={displayXAccessor}
            seriesName={param.param.symbol}
            xScale={xScale}
            xAccessor={xAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
        >
            <Chart
                id={2}
                height={barChartHeight}
                origin={barChartOrigin}
                yExtents={barChartExtents}
            >
                <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
            </Chart>
            <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
                <XAxis showGridLines showTickLabel={false} />
                <YAxis showGridLines tickFormat={pricesDisplayFormat} />
                <CandlestickSeries />
                <LineSeries yAccessor={ema26.accessor()} strokeStyle={ema26.stroke()} />
                <CurrentCoordinate
                    yAccessor={ema26.accessor()}
                    fillStyle={ema26.stroke()}
                />
                <LineSeries yAccessor={ema12.accessor()} strokeStyle={ema12.stroke()} />
                <CurrentCoordinate
                    yAccessor={ema12.accessor()}
                    fillStyle={ema12.stroke()}
                />
                <LineSeries yAccessor={sma20.accessor()} strokeStyle={sma20.stroke()} />
                <CurrentCoordinate
                    yAccessor={sma20.accessor()}
                    fillStyle={sma20.stroke()}
                />
                <LineSeries yAccessor={sma50.accessor()} strokeStyle={'#498060'} />
                <CurrentCoordinate
                    yAccessor={sma50.accessor()}
                    fillStyle={'#498060'}
                />
                <LineSeries yAccessor={sma200.accessor()} strokeStyle={'#ebaf42'} />
                <CurrentCoordinate
                    yAccessor={sma200.accessor()}
                    fillStyle={'#ebaf42'}
                />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />
                <EdgeIndicator
                    itemType="last"
                    rectWidth={margin.right}
                    fill={openCloseColor}
                    lineStroke={openCloseColor}
                    displayFormat={pricesDisplayFormat}
                    yAccessor={yEdgeIndicator}
                />
                <MovingAverageTooltip
                    origin={[8, 24]}
                    options={[
                        {
                            yAccessor: ema26.accessor(),
                            type: "EMA",
                            stroke: ema26.stroke(),
                            windowSize: ema26.options().windowSize
                        },
                        {
                            yAccessor: ema12.accessor(),
                            type: "EMA",
                            stroke: ema12.stroke(),
                            windowSize: ema12.options().windowSize
                        },
                        {
                            yAccessor: sma20.accessor(),
                            type: "SMA",
                            stroke: sma20.stroke(),
                            windowSize: sma20.options().windowSize
                        },
                        {
                            yAccessor: sma50.accessor(),
                            type: "SMA",
                            stroke: "#498060",
                            windowSize: sma50.options().windowSize
                        },
                        {
                            yAccessor: sma200.accessor(),
                            type: "SMA",
                            stroke: "#ebaf42",
                            windowSize: sma200.options().windowSize
                        }
                    ]}
                />

                <ZoomButtons />
                <OHLCTooltip origin={[8, 16]} />
            </Chart>
            <Chart
                id={4}
                height={elderRayHeight}
                yExtents={[0, elder.accessor()]}
                origin={elderRayOrigin}
                padding={{ top: 8, bottom: 8 }}
            >
                <XAxis ticks={5} showGridLines gridLinesStrokeStyle="#e0e3eb" />
                <YAxis ticks={4} tickFormat={pricesDisplayFormat} />

                <MouseCoordinateX
                    displayFormat={(v) => moment(v).format("DD MMM yyy H:m")}
                />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />

                <ElderRaySeries yAccessor={elder.accessor()} />

                <SingleValueTooltip
                    yAccessor={elder.accessor()}
                    yLabel="Elder Ray"
                    yDisplayFormat={(d) =>
                        `${pricesDisplayFormat(d.bullPower)}, ${pricesDisplayFormat(
                            d.bearPower
                        )}`
                    }
                    origin={[8, 16]}
                />
            </Chart>
            <CrossHairCursor />
        </ChartCanvas>
    );
};

