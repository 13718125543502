const initialState = {
    anchor: 'left',
    sector: [],
    open: false,
};

export function sector(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_SECTORS':
            return {
                ...state,
                sector: action.sector
            };

        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}