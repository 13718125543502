import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import {paymentAction, userAction} from '../_actions';
import { withRouter } from 'react-router-dom';

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    contentRoot: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class AddPayment extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(userAction.getUser());
    }
    handleChange = prop => event => {
        const { dispatch } = this.props;
        dispatch(paymentAction.onChangeProps(prop, event));
    };
    handleClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        let payload={
            currency: this.props.payment.currency,
            amount: Number.parseInt(this.props.payment.amount),
            user_id: this.props.payment.user_id
        }
        dispatch(paymentAction.createPayment(payload));
    }
    render() {
        const { classes } = this.props;
        const { user } = this.props.user;
        const { match : { params } } = this.props;
        function InsertText(props) {
            return <Typography>{'Add New Payment'}</Typography>;
        }
        function SegHeader() {
            return <InsertText />;
        }
        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar/>
                    <Nav />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SegHeader />
                            </Grid>

                        </Grid>
                        <br /><br />
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <Paper className={classes.contentRoot} elevation={1}>
                                        <form className={classes.container}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={4}>
                                                    <InputLabel shrink>
                                                        User
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        id="user_id"
                                                        label="User"
                                                        value={this.props.payment.user_id}
                                                        onChange={this.handleChange('user_id')}
                                                    >
                                                        <option value="">Select login</option>
                                                        {user.map(n => {
                                                            return (
                                                                <option value={n.id}>{n.login}</option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputLabel shrink >
                                                        Currency
                                                    </InputLabel>
                                                    <Select
                                                        id="currency"
                                                        label="Currency"
                                                        defaultValue={{ label: "Currency", value: "USD" }}
                                                        native
                                                        value={this.props.payment.currency}
                                                        onChange={this.handleChange('currency')}
                                                    >
                                                        <option value="USD">USD</option>
                                                        <option value="RUR">RUR</option>
                                                        <option value="EUR">EUR</option>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="amount"
                                                        label="Amount"
                                                        className={classes.textField}
                                                        value={this.props.payment.amount}
                                                        onChange={this.handleChange('amount')}
                                                    />
                                                </Grid>

                                            </Grid>


                                            <br />
                                            <Grid container spacing={5}>

                                                <Grid item xs={3}>
                                                </Grid>
                                                <Grid item xs={6} container justify="center">
                                                    <Grid container spacing={12}>
                                                        <Grid item xs={6} container justify="center">
                                                            <Button variant="contained" color="secondary" className={classes.button} component='a' href="/payments">Cancel</Button>
                                                        </Grid>
                                                        <Grid item xs={6} container justify="flex-start">
                                                            <Button variant="contained" color="primary" className={classes.button} onClick={(event) => this.handleClick(event)}>Save</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </div>
                            </Grid>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
AddPayment.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) =>{
    return state;
}

const connectedAddPaymentPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(AddPayment)));
export { connectedAddPaymentPage as AddPayment };