const initialState = {
    anchor: 'left',
    types: [],
    key: "",
    value: 0,
    open: false,
};

export function conditiontype(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_CONDITIONTYPES':
            return {
                ...state,
                types: action.types
            };

        default:
            return state
    }
}