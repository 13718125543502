import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import {EditCondition} from "./editcondition.component";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CachedIcon from '@material-ui/icons/Cached';
import { conditionAction } from '../_actions';


function getModalStyle() {
    return {
        display: 'flex',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function ModalBoxCondition(param) {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    console.log(param);

    const handleOpen = (params) => {
        const { dispatch } = params.props;
//        console.log(params);
        if (params.id) {
            dispatch(conditionAction.getConditionById(params.id));
        } else {
            dispatch(conditionAction.initCondition());
        }
        setOpen(true);
    };

    const handleReload = (params) => {
        const { dispatch } = params.props;
//        console.log(params);
        dispatch(conditionAction.checkConditionGroup(params.user_id, params.condition_type, params.group_name));
    };

    const handleClose = () => {
        setOpen(false);
    };

    let conditionSymbol = new Array;
    if (param.param.props.conditioncondition) {
        for (let value of param.param.props.conditioncondition.conditions) {
            if (value.key && value.value) {
                conditionSymbol[value.value] = value.key;
            }
        }
    }

    let conditionType = new Array;
    if (param.param.props.conditiontype) {
        for (let value of param.param.props.conditiontype.types) {
            if (value.key && value.value) {
                conditionType[value.value] = value.key;
            }
        }
    }

    let conditionParam = new Array;
    if (param.param.props.conditionparam) {
        for (let value of param.param.props.conditionparam.params) {
            if (value.key && value.value) {
                conditionParam[value.value] = value.key;
            }
        }
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <p id="simple-modal-description">
                <Grid container spacing={15}>
                    <Paper className={classes.root}>
                        <Grid container spacing={15} className={classes.root}>


                            <Grid item xs={12}>
                                <Card className={classes.root} variant="outlined">
                                    <EditCondition params={{id: param.param.id, modalOpen: setOpen, group_name: param.param.group_name, condition_type: param.param.condition_type, condition_type_id: param.param.condition_type_id, user_id: param.param.user_id}}/>

                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </p>
        </div>
    );

    const modal_box = (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );

    let reload_button = (
        <Typography variant="body2" component="p">
        </Typography>
    );

    if (param.param.from == "home") {
        reload_button = (
            <Button
                variant="contained"
                color="default"
                style={{width: '200px'}}
                className={classes.button}
                onClick={(event) => handleReload(param.param)}
                endIcon={<CachedIcon>Reload</CachedIcon>}
            >
                Reload
            </Button>
        );
    }


    if (param.param.style == "icon") {
        return (
            <Typography variant="body2" component="p">
            <div style={{
                position: 'relative',
                display: 'flex',
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <IconButton className={classes.button} aria-label="Edit" onClick={(event) => handleOpen(param.param)}>
                <EditIcon />
            </IconButton>
        <IconButton className={classes.button} aria-label="Delete" onClick={(event) => this.handleClick(event)}>
            <DeleteIcon />
        </IconButton>
            </div>
        {modal_box}
    </Typography>
        );
    } else {
        return (
            <Typography variant="body2" component="p">
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {reload_button}&nbsp;&nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        style={{width: '200px'}}
                        className={classes.button}
                        onClick={(event) => handleOpen(param.param)}
                        endIcon={<AddIcon>Add Condition</AddIcon>}
                    >
                        Add Condition
                    </Button>
                </div>
                {modal_box}
            </Typography>
        );
    }
}
