const initialState = {
    anchor: 'left',
    payment: [],
    open: false,
    id: '',
    user_id: '',
    user_login: '',
    amount: 0,
    currency: 'USD',
    time: 0
};

export function payment(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_PAYMENT':
            return {
                ...state,
                payment: action.payment
            };
        case 'PAYMENT_DETAIL':
            return {
                ...state,
                id: action.id,
                user_id: action.user_id,
                user_login: action.user_login,
                currency: action.currency,
                amount: action.amount,
                time: action.time
            };
        case "PAYMENT_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}