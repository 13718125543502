const initialState = {
    anchor: 'left',
    open: false,
    symbol: '',
    last_1min_ts: 0,
    last_5min_ts: 0,
    last_1day_ts: 0,
    last_1min_live_ts: 0,
    last_5min_live_ts: 0,
    last_15min_live_ts: 0,
    last_1hour_live_ts: 0,
    last_1day_live_ts: 0,
};

export function symbolhistorystatus(state = initialState, action) {
    switch (action.type) {
        case 'SYMBOL_SYNC_STATUS_DETAIL':
            return {
                ...state,
                symbol: action.id,
                last_1min_ts: action.last_1min_ts,
                last_5min_ts: action.last_5min_ts,
                last_1day_ts: action.last_1day_ts,
                last_1min_live_ts: action.last_1min_live_ts,
                last_5min_live_ts: action.last_5min_live_ts,
                last_15min_live_ts: action.last_15min_live_ts,
                last_1hour_live_ts: action.last_1hour_live_ts,
                last_1day_live_ts: action.last_1day_live_ts,
            };
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}