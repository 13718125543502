const initialState = {
    anchor: 'left',
    history: [],
    open: false,
    interval: '',
    symbol: '',
    timestamp_from: 0,
};

export function symbolhistory(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_HiSTORY':
            return {
                ...state,
                history: action.history,
                symbol: action.symbol,
                interval: action.interval,
                timestamp_from: action.timestamp_from
            };
        case "HANDLE_ON_CHANGE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}