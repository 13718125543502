import React, { Component } from 'react';
import './App.css';
import { Router, Switch, Route} from 'react-router-dom';
import { User } from './users/user.component';
import { AddUser } from './users/adduser.component'
import { EditUser } from './users/edituser.component'
import  { Login } from './login/';
import { Home } from './home/';
import { Payment } from './payments/payment.component';
import { AddPayment } from './payments/addpayment.component'
import { Symbol } from './symbols/symbol.component';
import { SymbolView } from './symbols/viewsymbol.component';
import { Condition } from './conditions/condition.component';

import history from './_helpers/history';
import { PrivateRoute } from './_components';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Router history={history}>
                    <div>
                        <Switch>
                            <PrivateRoute exact path='/home' component={Home} />
                            <PrivateRoute exact path='/users' component={User} />
                            <PrivateRoute exact path='/add-user' component={AddUser} />
                            <PrivateRoute exact path='/edit-user/:id' component={EditUser} />
                            <PrivateRoute exact path='/payments' component={Payment} />
                            <PrivateRoute exact path='/add-payment' component={AddPayment} />
                            <PrivateRoute exact path='/symbols' component={Symbol} />
                            <PrivateRoute exact path='/symbols/:id' component={SymbolView} />
                            <PrivateRoute exact path='/conditions' component={Condition} />
                            <Route exact path='/' component={Login} />
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}
export default App;