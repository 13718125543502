let access_token = localStorage.getItem('access_token');
let auth = localStorage.getItem('auth');

const initialState = auth ? { loggedIn: true, auth, access_token } : {};
export function authentication(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                loggingIn: true,
                auth: action.auth,
                access_token: action.access_token,
                refresh_token: action.refresh_token,
            };

        case 'LOGOUT_SUCCESS':
            return {
                auth: false
            };
        default:
            return state
    }
}