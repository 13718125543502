import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { userAction } from '../_actions';
import { withRouter } from 'react-router-dom';
const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    contentRoot: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});
class EditUser extends Component {
    handleChange = prop => event => {
        const { dispatch } = this.props;
        dispatch(userAction.onChangeProps(prop, event));
    };
    componentDidMount() {
        const { match : { params } } = this.props;
        if(params.id){
            const { dispatch } = this.props;
            dispatch(userAction.getUserById(params.id));
        }
    }
    handleClick(event){
        const { match : { params } } = this.props;
        const { dispatch } = this.props;
        let payload={
            lname: this.props.user.lname,
            fname: this.props.user.fname,
            currency: this.props.user.currency,
            phone: Number.parseInt(this.props.user.phone),
            saldo: this.props.user.saldo,
            login: this.props.user.login,
            ctime: this.props.user.ctime,
            utime: this.props.user.utime,
            lasttime: this.props.user.lasttime
        }
        if(params.id){
            dispatch(userAction.editUserInfo(params.id, payload));
        }else{
            dispatch(userAction.createUser(payload));
        }
    }
    render() {
        const { classes } = this.props;
        const { match : { params } } = this.props;
        function InsertText(props) {
            return <Typography>{'Add New User'}</Typography>;
        }

        function EditText(props) {
            return <Typography>{'Edit User'}</Typography>;
        }
        function SegHeader() {
            if(params.id){
                return <EditText />;
            }
            return <InsertText />;
        }
        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar/>
                    <Nav />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={3}>
                            <Grid item xs={1}>
                                <SegHeader />
                            </Grid>

                            <Grid item xs={3} container justify="flex-end">
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={10}>
                            <Grid item xs={12}>
                                <div>
                                    <Paper className={classes.contentRoot} elevation={1}>
                                        <form className={classes.container}>
                                            <Grid container spacing={12}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="fname"
                                                        label="First Name"
                                                        className={classes.textField}
                                                        value={this.props.user.fname}
                                                        onChange={this.handleChange('fname')}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="lname"
                                                        label="Last Name"
                                                        className={classes.textField}
                                                        value={this.props.user.lname}
                                                        onChange={this.handleChange('lname')}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        id="phone"
                                                        label="Phone"
                                                        className={classes.textField}
                                                        value={this.props.user.phone}
                                                        onChange={this.handleChange('phone')}
                                                        margin="normal"
                                                    />
                                                </Grid>

                                            </Grid>
                                            <br />
                                            <Grid container spacing={10}>

                                                <Grid item xs={4}>
                                                </Grid>
                                                <Grid item xs={3} container justify="center">
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={6} container justify="center">
                                                            <Button variant="contained" color="secondary" className={classes.button} component='a' href="/users">Cancel</Button>
                                                        </Grid>
                                                        <Grid item xs={6} container justify="flex-start">
                                                            <Button variant="contained" color="primary" className={classes.button} onClick={(event) => this.handleClick(event)}>Save</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </div>
                            </Grid>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
EditUser.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) =>{
    return state;
}

const connectedEditUserPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(EditUser)));
export { connectedEditUserPage as EditUser };