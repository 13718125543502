import React from 'react';
import ReactDOM from "react-dom";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import Typography from "@material-ui/core/Typography";
import {conditionAction} from "../_actions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Card from '@material-ui/core/Card';
import ModalBoxCondition from "../conditions/modal.component";


function getModalStyle() {
    return {
        display: 'flex',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function ModalBox(param) {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

   console.log(param);

    const handleOpen = (params) => {
        const { dispatch } = params.props;
        console.log(params);
        dispatch(conditionAction.checkConditionGroup(params.user.user_id, params.condition_type, params.group_name));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let conditionSymbol = new Array;
    if (param.param.props.conditioncondition) {
        for (let value of param.param.props.conditioncondition.conditions) {
            if (value.key && value.value) {
                conditionSymbol[value.value] = value.key;
            }
        }
    }

    let conditionType = new Array;
    if (param.param.props.conditiontype) {
        for (let value of param.param.props.conditiontype.types) {
            if (value.key && value.value) {
                conditionType[value.value] = value.key;
            }
        }
    }

    let conditionParam = new Array;
    if (param.param.props.conditionparam) {
        for (let value of param.param.props.conditionparam.params) {
            if (value.key && value.value) {
                conditionParam[value.value] = value.key;
            }
        }
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <p id="simple-modal-description">
                <Grid container spacing={15}>
                    <Paper className={classes.root}>
                        <Grid container spacing={15} className={classes.root}>

                            <Grid item xs={12}>
                        <Typography className={classes.title}>Condition for <b>{param.param.user.user_login}</b> Type: <b style={ { textTransform: "uppercase" } }>{param.param.condition_type}</b> Group: <b>{param.param.group_name}</b></Typography>
                            </Grid>
                            <br/><br/>
                            <Grid item xs={12}>
                                <Card className={classes.root} variant="outlined">
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell numeric>Pri</TableCell>
                                    <TableCell numeric>Param</TableCell>
                                    <TableCell numeric>Cond</TableCell>
                                    <TableCell>Target</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Factor</TableCell>
                                    <TableCell>T.Res</TableCell>
                                    <TableCell>T.From</TableCell>
                                    <TableCell>T.To</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {param.param.props.conditioncheck.conditions.map(n => {
                                    return (
                                        <TableRow key={n.id}>
                                            <TableCell numeric>{n.priority}</TableCell>
                                            <TableCell numeric>{conditionParam[n.param]}</TableCell>
                                            <TableCell>{conditionSymbol[n.condition_symbol]}</TableCell>
                                            <TableCell>{n.target}</TableCell>
                                            <TableCell>{n.value}</TableCell>
                                            <TableCell>{n.factor}</TableCell>
                                            <TableCell>{n.target_resolution}</TableCell>
                                            <TableCell>{n.target_from}</TableCell>
                                            <TableCell>{n.target_to}</TableCell>
                                            <TableCell>
                                                <ModalBoxCondition param={ { props: param.param.props, style: "icon", id: n.id, from:"home"} }/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                                    <ModalBoxCondition param={ {group_name: param.param.group_name, user_id: param.param.user.user_id, condition_type: param.param.condition_type, condition_type_id: param.param.condition_type_id, props: param.param.props, from:"home"} }/>

                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>Result: Amount: <b>{param.param.props.conditioncheck.amount}</b> Sum: <b>{param.param.props.conditioncheck.sum}</b> {param.param.props.conditioncheck.currency}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </p>
        </div>
    );

    return (
        <Typography variant="body2" component="p">{param.param.group_name}:&nbsp;
            <b>{param.param.amount_conditions}</b>&nbsp;
            <IconButton color="primary" aria-label="check condition" onClick={(event) => handleOpen(param.param) } size={"small"}>
                <CheckIcon />
            </IconButton>
            <IconButton color="secondary" aria-label="action condition" size={"small"}>
                <PlayForWorkIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </Typography>
    );
}
